import React from "react";

import { ButtonProps, Image, Stack, StackProps, Text } from "Atoms";
import { OnlinePaymentMethod } from "Types";
import { SwishLogoPng } from "Assets";
import { OnlineCheckoutGenericPaymentButton } from "./OnlineCheckoutGenericPaymentButton";

export const OnlineCheckoutSwishPaymentContent: React.FC<StackProps> = props => (
    <Stack stretch={2} isInline align="center" {...props}>
        <Text as="span">Swish</Text>
        <Image src={SwishLogoPng} size={6} />
    </Stack>
);

type Props = {
    onClickPay: (paymentMethod: OnlinePaymentMethod) => void;
} & ButtonProps;

export const OnlineCheckoutSwishPaymentButton = ({ onClickPay, ...rest }: Props) => (
    <OnlineCheckoutGenericPaymentButton onClick={() => onClickPay(OnlinePaymentMethod.SWISH)} {...rest}>
        <OnlineCheckoutSwishPaymentContent />
    </OnlineCheckoutGenericPaymentButton>
);
