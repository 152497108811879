import React, { useMemo, useEffect } from "react";
import moment from "moment";

import { ActiveHour, Settings } from "Types";
import { useOnline, useLanguage, EatingPreference } from "Providers";
import { Flex, Box } from "Atoms";
import {
    getPickupStops,
    getCustomerTimeStops,
    getSpecificCateringDatePickupStops,
    cateringExcludedDates
} from "../../utils";
import { DateOrTimePicker } from "./components";
import { CustomerPickupFormComponent, NO_CATERING_PICKUP_HOURS_DEFAULT_TIME } from "..";
import { getHoursAndMinutes } from "../../../../../../../utils";
import { getCateringText } from "./utils";
import { useQoplaStore, useOnlineStore } from "Stores";

type Props = {
    onlineActiveHour: ActiveHour | undefined;
    showDisabledDates?: boolean;
} & CustomerPickupFormComponent;

export const PickupOptions: React.FC<Props> = ({
    onlineActiveHour,
    showDisabledDates = true,
    isLessThanFifteenMin,
    ...formikProps
}) => {
    const { values } = formikProps;

    const { translate, userLanguage } = useLanguage();
    const { selectedShop: shop } = useQoplaStore();
    const { table, backendDiff } = useOnlineStore();
    const activeHours = shop?.activeHours as ActiveHour[];
    const settings = shop?.settings as Settings;
    const { cateringSettings, onlineSettings } = settings;
    const rushHour = onlineSettings && onlineSettings.rushHour;
    const quickServeTable =
        settings.shopTables?.find(table => table.id === onlineSettings?.qrQuickServe?.qrPickupOptionTableId) ?? null;
    const isQrTablesOrdering = !!table && quickServeTable?.id != table?.id;
    const shouldOnlyHaveAsap = onlineSettings.onlyAsap || isQrTablesOrdering || rushHour;

    const isToday = moment().add(backendDiff, "ms").format("YYYY-MM-DD") === values.pickupDate;
    const isCatering = values.eatingPreference === EatingPreference.CATERING;
    const shouldHideTimePicker = isCatering && cateringSettings.noPickupHours;

    const customCateringDates = !!values.tmpDeliveryInformation?.customCateringDates?.length
        ? values.tmpDeliveryInformation?.customCateringDates
        : cateringSettings.customCateringDates;
    const excludedDates = isCatering
        ? cateringExcludedDates(
              customCateringDates,
              cateringSettings.customSpecificCateringDates,
              cateringSettings.lastOrderDay,
              values.pickupStartDate
          )
        : [];

    // When catering we want to include the next week's date in order to get a full week's selection
    const amountOfDays = isCatering ? 9 : 7;

    let dateStops = useMemo(() => {
        if (isCatering && cateringSettings?.customSpecificCateringDates?.length) {
            return getSpecificCateringDatePickupStops(cateringSettings.customSpecificCateringDates, userLanguage);
        } else {
            return getPickupStops(
                values.onlineActiveHours,
                values.pickupStartDate?.clone(),
                shouldOnlyHaveAsap,
                userLanguage,
                showDisabledDates,
                amountOfDays,
                excludedDates
            );
        }
    }, [values.pickupStartDate, excludedDates]);

    if (
        !!cateringSettings.customSpecificCateringDates &&
        !!cateringSettings.customSpecificCateringDates.length &&
        !!cateringSettings.lastOrderDay
    ) {
        if (moment(cateringSettings.lastOrderDay.date).isBefore(moment())) {
            dateStops = [{ text: translate("cateringPeriodEnded"), value: "", disabled: true }];
        }
    }

    useEffect(() => {
        if (dateStops.length === 1) {
            const [dateStop] = dateStops;
            formikProps.setValues({
                ...formikProps.values,
                pickupDate: dateStop.value,
                pickupTime: shouldHideTimePicker ? NO_CATERING_PICKUP_HOURS_DEFAULT_TIME : formikProps.values.pickupTime
            });
        }
    }, []);

    const _isLessThanFifteenMin = !isCatering && isLessThanFifteenMin;

    const timeStops = useMemo(() => {
        if (isCatering && !!cateringSettings.noPickupHours) {
            return [];
        } else {
            return getCustomerTimeStops(
                onlineActiveHour,
                values,
                shouldOnlyHaveAsap,
                isToday,
                cateringSettings.noticeHours,
                onlineSettings.onlineOrderPickupTimesPostpone,
                userLanguage,
                activeHours,
                _isLessThanFifteenMin,
                backendDiff,
                onlineSettings.alwaysAllowPostponePickupTime
            );
        }
    }, [values.pickupDate, values.eatingPreference, userLanguage]);

    const onDatePickerClick = (target: any) => {
        const { value } = target;
        formikProps.setValues({
            ...formikProps.values,
            pickupTime: shouldHideTimePicker ? NO_CATERING_PICKUP_HOURS_DEFAULT_TIME : "",
            pickupDate: value,
            isAfterMidnight: false
        });
    };
    const onTimePickerClick = (target: any) => {
        const { value, selectedIndex } = target;
        const selectedOption = target.options[selectedIndex];
        const selectedDateInt = selectedOption.dataset.container;

        const hasPickedEarliest =
            timeStops &&
            timeStops.find(timeStop => timeStop.text.startsWith(translate("earliest")) && timeStop.value === value);
        const [selectedHour] = getHoursAndMinutes(value);
        const [startingHour] =
            onlineActiveHour && onlineActiveHour.startingHour ? getHoursAndMinutes(onlineActiveHour.startingHour) : [0];

        let pickupDate = values.pickupDate;
        const pickupDateInt = pickupDate.replace(/-/g, "");
        const isAfterMidnight = selectedHour < startingHour;
        if (isAfterMidnight && pickupDateInt < selectedDateInt) {
            pickupDate = moment(pickupDate).add(1, "day").format("YYYY-MM-DD");
        }
        formikProps.setValues({
            ...formikProps.values,
            pickupTime: value,
            pickupDate,
            isAfterMidnight,
            isEarliest: !!hasPickedEarliest
        });
    };

    const cateringHelperText = getCateringText(
        customCateringDates,
        cateringSettings.customSpecificCateringDates,
        excludedDates,
        values.pickupDate
    );

    return (
        <Flex direction="column">
            <Box mb={6}>
                <DateOrTimePicker
                    type="DATE"
                    isLessThanFifteenMin={_isLessThanFifteenMin}
                    stops={dateStops}
                    onChange={onDatePickerClick}
                    helperText={cateringHelperText}
                    {...formikProps}
                />
            </Box>
            {!shouldHideTimePicker && (
                <Box mb={6}>
                    <DateOrTimePicker
                        type="TIME"
                        isLessThanFifteenMin={_isLessThanFifteenMin}
                        stops={timeStops}
                        onChange={onTimePickerClick}
                        {...formikProps}
                    />
                </Box>
            )}
        </Flex>
    );
};
