import React, { useState } from "react";

import { Modal, ModalBody, ModalCloseBtn, ModalHeader, ModalActions } from "Molecules";
import { Box, Button, NewGrid as Grid, Header, Switch, Text, Stack } from "Atoms";
import { IModalContext, Languagekey, modals, useLanguage } from "Providers";
import { LanguagePicker } from "Components";
import { CookieCustomerConsent, COOKIE_CONSENT_TYPES } from "Types";
import { useAnonymousUserId } from "OnlineHooks";

type Props = {
    setConsentCookie: (consent?: CookieCustomerConsent) => void;
};

type CookieDisplayProps = {
    name: Languagekey;
    isSelected: boolean;
    isPreSelected?: boolean;
    onSwitch: (name: string, selection: boolean) => void;
};

const CookieDisplay: React.FC<CookieDisplayProps> = ({ name, isSelected, onSwitch, isPreSelected = false }) => {
    const { translate } = useLanguage();
    const cookieTextKey = "CookieText";
    const languageKey = (name + cookieTextKey) as Languagekey;
    return (
        <Grid templateColumns="2fr 1fr" templateRows="auto" mb={6}>
            <Header as="h5" size="md" style={{ gridColumn: "1" }} justifyContent="center">
                {translate(name)}
            </Header>
            <Box marginLeft="auto" mr={6} style={{ gridColumn: "2" }} justifyContent="center">
                <Switch
                    size="lg"
                    isDisabled={isPreSelected}
                    isChecked={isSelected}
                    cursor={isPreSelected ? "not-allowed" : "pointer"}
                    onChange={() => {
                        onSwitch(name, !isSelected);
                    }}
                />
            </Box>

            <Text color="gray.700" style={{ gridColumn: "1" }}>
                {translate(languageKey)}
            </Text>
        </Grid>
    );
};

export const OnlineCookiesManagerModal: React.FC<IModalContext<Props>> = ({
    closeModal,
    modalContent: { setConsentCookie }
}) => {
    const [cookieSelections, setCookiesSelections] = useState<CookieCustomerConsent>({
        essential: true,
        functional: false,
        analytics: false,
        marketing: false
    });

    const { translate } = useLanguage();
    const { setAnonymousUserId } = useAnonymousUserId();

    const onCookieSelections = (name: string, selection: boolean) => {
        setCookiesSelections(prev => ({ ...prev, [name]: selection }));
    };

    const onSetGlobalCookies = (acceptAll: boolean) => {
        if (acceptAll) {
            const globalCookies = { ...cookieSelections };
            Reflect.ownKeys(globalCookies).forEach(key => Reflect.set(globalCookies, key, true));
            setConsentCookie(globalCookies);
        } else {
            setConsentCookie(cookieSelections);
        }
        setAnonymousUserId(acceptAll ? acceptAll : cookieSelections.functional);
        closeModal(modals.cookieManagerModal);
    };

    return (
        <Modal
            size="md"
            open
            onClose={() => closeModal(modals.cookieManagerModal)}
            isScrolling
            overlayProps={{ zIndex: 1400 }}
        >
            <ModalHeader fontSize="2xl">
                <LanguagePicker size="sm" />
                <Header as="h5" size="lg" mb={2}>{`${translate("manage")} cookies`}</Header>
                <ModalCloseBtn />
            </ModalHeader>
            <ModalBody pt={2} px={0}>
                <Stack mx={6}>
                    {Object.entries(cookieSelections).map(([key, value]: [string, boolean]) => {
                        return (
                            <CookieDisplay
                                key={key}
                                name={key as Languagekey}
                                isPreSelected={key === COOKIE_CONSENT_TYPES.ESSENTIAL}
                                isSelected={value}
                                onSwitch={onCookieSelections}
                            />
                        );
                    })}
                </Stack>
            </ModalBody>
            <ModalActions display="flex" justifyContent="space-between">
                <Button variant="outline" onClick={() => onSetGlobalCookies(false)}>{`${translate("save")} ${translate(
                    "settings"
                ).toLowerCase()}`}</Button>
                <Button themeColor="blue" onClick={() => onSetGlobalCookies(true)}>
                    {translate("acceptAll")}
                </Button>
            </ModalActions>
        </Modal>
    );
};
