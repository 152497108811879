import React from "react";
import { useMedia } from "react-media";

import { Modal, ModalHeader, ModalBody, ModalActions, ModalCloseBtn } from "Molecules";
import { IModalContext, useLanguage, modals, Languagekey } from "Providers";
import { Flex, Header, Button } from "Atoms";
import { useOrderWindowsStore } from "Stores";
import { GLOBAL_BREAK_POINTS } from "Constants";
import { LastOrderIssue } from "Types";

export const OnlineLastOrderIssuesModal: React.FC<IModalContext<{}>> = ({ closeModal }) => {
    const isASmallerScreen = useMedia({ query: GLOBAL_BREAK_POINTS.LG });
    const { translate } = useLanguage();
    const { resetLastOrderIssues, lastOrderIssues } = useOrderWindowsStore();

    const onCloseLastOrderModal = () => {
        resetLastOrderIssues();
        closeModal(modals.onlineLastOrderIssuesModal);
    };

    /** Grouping Last Order Issues together by product id
     * Bundle items can cause multiple issues under one
     */
    const groupedLastOrderIssues =
        lastOrderIssues?.reduce((issues: Map<string, LastOrderIssue[]>, lastOrderIssue: LastOrderIssue) => {
            const productId = lastOrderIssue.productId;
            if (!issues.has(productId)) {
                issues.set(productId, [lastOrderIssue]);
            } else {
                const issue = issues.get(productId);
                const groupedIssues = [...issue!, lastOrderIssue];
                issues.set(productId, groupedIssues);
            }
            return issues;
        }, new Map<string, LastOrderIssue[]>()) ?? new Map<string, LastOrderIssue[]>();

    const displayLastOrderIssues = Array.from(groupedLastOrderIssues);

    return (
        <Modal open closeOnEscape rounded="md" isScrolling onClose={onCloseLastOrderModal}>
            <ModalHeader px={4}>
                <ModalCloseBtn size={"md"} onClick={onCloseLastOrderModal} />
                <Header as="h4" size={isASmallerScreen ? "sm" : "md"}>
                    {translate("followingProductsNotAdded")}
                </Header>
            </ModalHeader>
            <ModalBody p={8}>
                <Flex direction="column">
                    {displayLastOrderIssues?.map(([productId, issues]) => {
                        const productName = issues[0].lastOrderProduct.name;
                        return (
                            <React.Fragment key={productId}>
                                <Header color="gray.900" size={"sm"} as="h4" p={0} m={0} mb={2}>
                                    {productName}
                                </Header>
                                {issues.map(issue => {
                                    const reason = issue.lastOrderProductReason as Languagekey;
                                    const hasAdditionalReason = !!issue.addonOrModificationIssues?.length;
                                    const extraMissingText = hasAdditionalReason
                                        ? `(${issue.addonOrModificationIssues?.join(", ")})`
                                        : "";
                                    return (
                                        <Header
                                            color="gray.700"
                                            size={isASmallerScreen ? "xs" : "sm"}
                                            as="h4"
                                            p={0}
                                            m={0}
                                            mb={4}
                                            ml={4}
                                            key={issue.id}
                                        >
                                            {`- ${translate(reason)} ${extraMissingText}`}
                                        </Header>
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
                </Flex>
            </ModalBody>
            <ModalActions display="flex" justifyContent="flex-end" alignItems="center" px={4}>
                <Header as="h4" size="sm" p={0} m={0} mr={4}>
                   {translate("selectSomethingElse")}
                </Header>
                <Button themeColor="blue" variant="outline" onClick={onCloseLastOrderModal}>
                    {translate("continueAlt")}
                </Button>
            </ModalActions>
        </Modal>
    );
};
