import React, { useRef, useState, useEffect } from "react";
import { useMedia } from "react-media";
import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft";
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight";
import { deviceDetect } from "react-device-detect";
import { capitalize } from "lodash";

import { useOrderWindowsStore, useOnlineStore, useQoplaStore } from "Stores";
import {
    GOOGLE_ANALYTICS_EVENT_ACTION,
    GOOGLE_ANALYTICS_EVENT_CATEGORY,
    LastOrderDTO,
    LastOrderProduct,
    OnlineMenu,
    UserSubscriptions
} from "Types";
import { Flex, Button, Header, Image, Box, PseudoBox, Truncate } from "Atoms";
import { gaProductionEvent, getLastOrderImageUrl, getMonthNameFromUserLocaleAndDate } from "Utils";
import { GLOBAL_BREAK_POINTS } from "Constants";
import { useLanguage, useOnline, usePos } from "Providers";
import { useEffectOnce } from "Hooks";
import { shouldDisableOnlineOrderCart as shouldDisableLastOrders } from "OnlineUtils";

export const OnlineLastOrders: React.FC<{ userSubscriptions: UserSubscriptions; lastOrders: LastOrderDTO[] }> = ({
    userSubscriptions,
    lastOrders
}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(true);

    const lastOrderRefs = Array.from({ length: 3 }, () => useRef(null));
    const containerRef = useRef(null);

    const { translate, userLocale } = useLanguage();
    const isASmallerScreen = useMedia({ query: GLOBAL_BREAK_POINTS.LG });
    const deviceInformation = deviceDetect("");

    const { addLastOrderProductsToCart, activeMenus, getActiveOrderWindow } = useOrderWindowsStore();
    const { expandMobileCart, setExpandMobileCart } = useOnlineStore();
    const { browseMode } = useOnline();
    const { menus } = usePos();
    const { selectedShop } = useQoplaStore();

    const disableLastOrders = shouldDisableLastOrders(menus, browseMode);

    const isSafariBrowser = deviceInformation.browserName === "Safari";

    const pseudoStyles = {
        transform: ["", "", "", "scale(1.01)"],
        boxShadow: ["", "", "", "0 8px 12px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)"]
    };

    /** Add last order products & if a certain screen size open cart */
    const addLastOrderAndOpenCart = (orderId: string, userSubscriptions: UserSubscriptions) => {
        addLastOrderProductsToCart(orderId, userSubscriptions);
        if (isASmallerScreen) {
            /** Check if cart products have been added then open cart list modal */
            const orderWindow = getActiveOrderWindow();
            if (!!orderWindow?.cartProducts.length) {
                setExpandMobileCart(!expandMobileCart);
            }
        }
        gaProductionEvent({
            category: GOOGLE_ANALYTICS_EVENT_CATEGORY.ONLINE_ORDER,
            action: GOOGLE_ANALYTICS_EVENT_ACTION.ADD_LAST_ORDER
        });
    };

    /** Sort order products in orders by price (higher price === displayed imageUrl) */
    const lastOrderProductsSorted = lastOrders?.map((lastOrder: LastOrderDTO) => {
        return {
            ...lastOrder,
            lastOrderProducts: lastOrder.lastOrderProducts?.sort((a, b) => b.totalPrice - a.totalPrice)
        };
    });

    /** Get first image url - based on price (Each product list in Last Order has been sorted by "price" from that order) */
    const getLastOrderImage = (lastOrder: LastOrderDTO) => {
        return activeMenus.reduce((imageUrl: string, onlineMenu: OnlineMenu) => {
            if (!!imageUrl) {
                return imageUrl;
            }
            lastOrder?.lastOrderProducts?.forEach(lastOrder => {
                if (!imageUrl) {
                    const refImageUrl = getLastOrderImageUrl(onlineMenu, lastOrder);
                    if (refImageUrl) {
                        imageUrl = refImageUrl;
                    }
                }
            });
            return imageUrl;
        }, "");
    };

    /** Tile text - max 2 product + if more how many more products ordered */
    const getLastOrderProductText = (lastOrder: LastOrderDTO) => {
        const lastOrderProducts = lastOrder.lastOrderProducts!;
        const finalIndex = lastOrderProducts.length - 1;
        const MAX_DISPLAY = 2;

        return lastOrderProducts.reduce((productNames: string[], lastOrderProduct: LastOrderProduct, index: number) => {
            if (index < MAX_DISPLAY) {
                const productName =
                    lastOrderProduct.quantity > 1
                        ? `${lastOrderProduct.quantity} x ${lastOrderProduct.name}`
                        : lastOrderProduct.name;
                productNames = [...productNames, productName];
            }

            if (index === finalIndex && index >= MAX_DISPLAY) {
                const remainingCount = index - MAX_DISPLAY + 1;
                const multipleProducts = remainingCount > 1;
                productNames = [
                    ...productNames,
                    `+ ${remainingCount} ${multipleProducts ? translate("products") : translate("product")}`
                ];
            }

            return productNames;
        }, []);
    };

    /** Scroll orders by offset and index */
    const scrollOrders = (scrollLeft: boolean) => {
        const _currentIndex = scrollLeft ? currentIndex + 1 : currentIndex - 1;

        if (!!lastOrderRefs && containerRef.current) {
            /** Get Ref (Last Order Tile) */
            const refLastOrder = lastOrderRefs[_currentIndex]?.current
                ? (lastOrderRefs[_currentIndex].current as unknown as HTMLElement)
                : null;

            if (refLastOrder) {
                const container = containerRef.current as HTMLElement;

                /** Get Offset of next refLastOrder Tile */
                const offsetScroll = refLastOrder?.offsetLeft ?? 0;
                const finalIndex = lastOrders.length - 1;

                /** If you use Safari (on mac) - scroll doesn't work with 'smooth' */
                container?.scrollTo({
                    left: offsetScroll - 50,
                    behavior: isSafariBrowser ? "auto" : "smooth"
                });

                /** Behaviour if to show buttons */
                const showLeftButton = offsetScroll > 16;
                const showRightButton = finalIndex !== _currentIndex;

                setShowLeftButton(showLeftButton);
                setShowRightButton(showRightButton);
                setCurrentIndex(_currentIndex);
            }
        }
    };

    /** Setting right scoll button based on screen size + lastOrders */
    const setRightScrollButton = () => {
        /** Initial set - if last orders on a larger screen are only 2 */
        const hideRightScrollButton_LargeScreen = lastOrders.length < 3 && !isASmallerScreen;
        /** Initial set - if last orders on smaller screen are only 1 */
        const hideRightScrollButton_SmallScreen = lastOrders.length === 1 && isASmallerScreen;

        if (hideRightScrollButton_LargeScreen) {
            setShowRightButton(false);
        } else if (hideRightScrollButton_SmallScreen) {
            setShowRightButton(false);
        } else {
            setShowRightButton(true);
        }
    };

    /** If screen size is changed then reset scrolling  */
    const resetScroll = () => {
        if (containerRef?.current) {
            const container = containerRef.current as HTMLElement;
            /** If you use Safari (on mac) - scroll doesn't work with 'smooth' */
            container?.scrollTo({
                left: 0,
                behavior: isSafariBrowser ? "auto" : "smooth"
            });
            setShowLeftButton(false);
            setRightScrollButton();
            setCurrentIndex(0);
        }
    };

    /** Initial Set */
    useEffectOnce(() => {
        if (!!lastOrders) {
            setRightScrollButton();
        }
    });

    /** Change scroll by re-size  */
    useEffect(() => {
        resetScroll();
    }, [isASmallerScreen, lastOrders]);

    return (
        <>
            <Flex flexDirection="column" pb={4} position="relative" width="100%">
                <Header as="h4" size="md" width="100%" ml={4}>
                    {translate("previousOrders")}
                </Header>
                {showLeftButton && (
                    <PseudoBox
                        _hover={{ cursor: "pointer" }}
                        position="absolute"
                        left={10}
                        top="36%"
                        transform="translate(-50%, -50%)"
                        paddingX={3}
                        zIndex={1}
                        mt={8}
                        height="40px"
                        bg="whiteAlpha.600"
                        rounded="xxl"
                        onClick={() => scrollOrders(false)}
                    >
                        <Box color="newPrimary" fontSize="1.5rem" as={FaChevronLeft} height="100%" />
                    </PseudoBox>
                )}
                <Flex
                    position="relative"
                    overflow="hidden"
                    flexWrap="nowrap"
                    cursor="pointer"
                    pr={4}
                    pb={4}
                    ref={containerRef}
                    width="100%"
                    ml={isASmallerScreen ? 4 : 0}
                    style={{ WebkitOverflowScrolling: "touch" }}
                >
                    {lastOrderProductsSorted.map((lastOrder: LastOrderDTO, index: number) => {
                        /** get highest price url / if not use banner url */
                        const productUrl = getLastOrderImage(lastOrder);
                        const imageUrl = !!productUrl ? productUrl : selectedShop?.bannerUrl;

                        /** Select product name text */
                        const productNames = getLastOrderProductText(lastOrder);

                        /** If not is view make it transparent */
                        const tileIsTransparent = currentIndex !== index && isASmallerScreen;

                        /** Get short month + day (in user language) */
                        const purchaseDate = new Date(lastOrder.purchaseDate);
                        const shortMonth = getMonthNameFromUserLocaleAndDate(userLocale, purchaseDate, "short");
                        const dayAndMonth = `${purchaseDate.getDate()} ${capitalize(shortMonth)}`;

                        return (
                            <Button
                                ref={lastOrderRefs[index]}
                                position="relative"
                                border="none"
                                height="100px"
                                maxHeight="100px"
                                fontWeight={400}
                                mr={4}
                                borderRadius="0.75rem"
                                {...(isASmallerScreen && { minWidth: "90%" })}
                                {...(!isASmallerScreen && { width: "100%", minWidth: "50%" })}
                                whiteSpace="pre-wrap"
                                backgroundColor="base"
                                cursor={"pointer"}
                                opacity={tileIsTransparent ? "0.5" : "1"}
                                boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
                                key={lastOrder.orderId}
                                overflow="hidden"
                                transition="all 0.2s cubic-bezier(0.44, 2.1, 0.04, 1.65)"
                                onClick={() => addLastOrderAndOpenCart(lastOrder.orderId, userSubscriptions)}
                                _hover={pseudoStyles}
                                _active={pseudoStyles}
                                isDisabled={disableLastOrders}
                            >
                                <Flex width="100%" textAlign="left">
                                    <Flex flex="1" direction="column" pt={3}>
                                        {productNames.map((name, index) => {
                                            return (
                                                <Header
                                                    key={index}
                                                    color="gray.700"
                                                    size="sm"
                                                    as="h4"
                                                    p={0}
                                                    m={0}
                                                    mb={1}
                                                >
                                                    {/** @ts-ignore */}
                                                    <Truncate lines={1}>{name}</Truncate>
                                                </Header>
                                            );
                                        })}
                                        <Flex justifyContent="flex-start" alignItems="flex-end" mb={2}>
                                            <Header key={index} color="gray.800" size="sm" as="h4" p={0} m={0}>
                                                {dayAndMonth}
                                            </Header>
                                        </Flex>
                                    </Flex>
                                    <Flex flex="0 0 100px" padding={1}>
                                        {!!imageUrl ? (
                                            <Image
                                                alt={"name"}
                                                src={imageUrl}
                                                fallbackSrc={""}
                                                maxHeight="100px"
                                                maxWidth="100px"
                                                objectFit="cover"
                                                borderRadius="0.75rem"
                                                ml={3}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </Flex>
                                </Flex>
                            </Button>
                        );
                    })}
                </Flex>
                {showRightButton && (
                    <PseudoBox
                        _hover={{ cursor: "pointer" }}
                        position="absolute"
                        right={-20}
                        top="36%"
                        transform="translate(-50%, -50%)"
                        paddingX={3}
                        zIndex={1}
                        height="40px"
                        mt={8}
                        mr={isASmallerScreen ? 4 : 0}
                        bg="whiteAlpha.600"
                        rounded="xxl"
                        onClick={() => {
                            scrollOrders(true);
                        }}
                    >
                        <Box color="newPrimary" fontSize="1.5rem" as={FaChevronRight} height="100%" />
                    </PseudoBox>
                )}
            </Flex>
        </>
    );
};
