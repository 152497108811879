import React, { useRef, useState, useEffect } from "react";
import Media from "react-media";
import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft";
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight";
import { FaShoppingBag } from "@react-icons/all-files/fa/FaShoppingBag";
import { FaShippingFast } from "@react-icons/all-files/fa/FaShippingFast";
import { FaGift } from "@react-icons/all-files/fa/FaGift";
import { isMobile } from "react-device-detect";
import { MdRestaurant } from "@react-icons/all-files/md/MdRestaurant";
import { sortBy } from "lodash";

import { Box, PseudoBox, Header, Text, NewButton, Flex } from "Atoms";
import { useEventCallback } from "Hooks";
import { EatingOption } from "Types";
import { useLanguage, useOnline } from "Providers";
import { OnlineOrderCategoriesNavigation } from "./components";
import { getOnlineProductCategoriesActiveIndexes } from "OnlineUtils";
import { getActiveHoursText } from "../../utils";
import { useOnlineStore, useOrderWindowsStore } from "Stores";

const eatingOptionIcons = {
    EAT_HERE: MdRestaurant,
    TAKE_AWAY: FaShoppingBag,
    HOME_DELIVERY: FaShippingFast,
    CATERING: FaGift
};

export const OnlineOrderMenusNavigation: React.FC<{}> = React.memo(() => {
    const { setActiveIndexes, activeMenu, setActiveMenu, allOnlineMenus } = useOnlineStore();
    const { activeMenus } = useOrderWindowsStore();
    const menuRef = useRef<any>(null);
    const categoryRef = useRef<any>(null);

    const { translate } = useLanguage();
    const {
        browseMode: { hasNoActiveMenus },
        foodOptions,
        getTodaysActiveHour
    } = useOnline();

    const onlineMenus = hasNoActiveMenus ? allOnlineMenus : activeMenus;
    const onlineMenu = activeMenu;

    const [menuScrollState, setMenuScrollState] = useState({
        scrollLeft: 0,
        scrollWidth: 0,
        offsetWidth: 0
    });
    const [categoryScrollState, setCategoryScrollState] = useState({
        scrollLeft: 0,
        scrollWidth: 0,
        offsetWidth: 0
    });

    useEffect(() => {
        if (menuRef && menuRef.current) {
            setMenuScrollState((currState: any) => ({
                ...currState,
                scrollWidth: menuRef.current.scrollWidth,
                offsetWidth: menuRef.current.offsetWidth
            }));
        }
        if (categoryRef && categoryRef.current) {
            setCategoryScrollState((currState: any) => ({
                ...currState,
                scrollWidth: categoryRef.current.scrollWidth,
                offsetWidth: categoryRef.current.offsetWidth
            }));
        }
    }, []);

    useEventCallback({
        eventName: "resize",
        callback: () => {
            if (menuRef && menuRef.current) {
                setMenuScrollState((currState: any) => ({
                    ...currState,
                    scrollWidth: menuRef.current.scrollWidth,
                    offsetWidth: menuRef.current.offsetWidth
                }));
            }
            if (categoryRef && categoryRef.current) {
                setCategoryScrollState((currState: any) => ({
                    ...currState,
                    scrollWidth: categoryRef.current.scrollWidth,
                    offsetWidth: categoryRef.current.offsetWidth
                }));
            }
        }
    });

    if (!onlineMenu) return <div />;

    const sortedMenus = sortBy(onlineMenus, "sortOrder");

    const handleScrollTo = (ref: any, scrollState: any, direction: string) => {
        const el = ref.current;
        const { scrollLeft } = scrollState;

        el.scrollTo({
            left: direction === "right" ? scrollLeft + 200 : direction === "left" ? scrollLeft - 200 : 0,
            behavior: "smooth"
        });
    };

    return (
        <Flex direction="column" overflowY="hidden" paddingY={4}>
            <Media queries={{ small: { maxWidth: 991 } }}>
                {matches =>
                    matches.small && !hasNoActiveMenus && onlineMenus.length === 1 ? (
                        <Box
                            overflowX="auto"
                            display="flex"
                            id="hidden-scroll"
                            ref={categoryRef}
                            onScroll={() => {
                                setCategoryScrollState((currState: any) => ({
                                    ...currState,
                                    scrollLeft: categoryRef.current.scrollLeft
                                }));
                            }}
                        >
                            <Box display="flex" flexWrap="nowrap">
                                {categoryScrollState.scrollLeft > 16 && (
                                    <PseudoBox
                                        _hover={{ cursor: "pointer" }}
                                        position="absolute"
                                        left={[0, 0, 6, 12, 24]}
                                        paddingX={3}
                                        zIndex={1}
                                        height="30px"
                                        background="linear-gradient(90deg, white, #FFFFFF00 200%)"
                                        onClick={() => {
                                            handleScrollTo(categoryRef, categoryScrollState, "left");
                                        }}
                                    >
                                        <Box color="primaryDark" as={FaChevronLeft} height="100%" />
                                    </PseudoBox>
                                )}
                                <OnlineOrderCategoriesNavigation
                                    onlineProductCategories={onlineMenu.onlineProductCategories}
                                    small={matches.small}
                                />
                                {categoryScrollState.scrollLeft <
                                    categoryScrollState.scrollWidth - categoryScrollState.offsetWidth - 16 && (
                                    <PseudoBox
                                        _hover={{ cursor: "pointer" }}
                                        position="absolute"
                                        right={[0, 0, 6, 12, 24]}
                                        paddingX={3}
                                        zIndex={1}
                                        height="30px"
                                        background="linear-gradient(-90deg, white, #FFFFFF00 200%)"
                                        onClick={() => {
                                            handleScrollTo(categoryRef, categoryScrollState, "right");
                                        }}
                                    >
                                        <Box color="primaryDark" as={FaChevronRight} height="100%" />
                                    </PseudoBox>
                                )}
                            </Box>
                        </Box>
                    ) : (
                        <Box>
                            <Box
                                overflowX="auto"
                                display="flex"
                                paddingBottom={2}
                                id="hidden-scroll"
                                ref={menuRef}
                                onScroll={() => {
                                    setMenuScrollState((currState: any) => ({
                                        ...currState,
                                        scrollLeft: menuRef.current.scrollLeft
                                    }));
                                }}
                            >
                                {menuScrollState.scrollLeft > 16 && (
                                    <PseudoBox
                                        _hover={{ cursor: "pointer" }}
                                        position="absolute"
                                        left={[0, 0, 6, 12, 24]}
                                        paddingX={3}
                                        zIndex={1}
                                        height="63px"
                                        backgroundColor="white"
                                        background="linear-gradient(90deg, white, #FFFFFF00 200%)"
                                        onClick={() => {
                                            handleScrollTo(menuRef, menuScrollState, "left");
                                        }}
                                    >
                                        <Box color="primaryDark" as={FaChevronLeft} height="100%" />
                                    </PseudoBox>
                                )}
                                <Box display="flex" flexWrap="nowrap">
                                    {sortedMenus.map(menu => {
                                        const { name, id, activeHours, eatingOptions, isInactive } = menu;
                                        const isCateringMenu = eatingOptions.includes(EatingOption.CATERING);

                                        let activeHoursText = isCateringMenu
                                            ? translate("alwaysOpen")
                                            : getActiveHoursText(
                                                  isInactive!,
                                                  activeHours,
                                                  foodOptions.pickupOptions.date,
                                                  translate("inactive"),
                                                  getTodaysActiveHour
                                              );

                                        return (
                                            <Box
                                                key={`secondary-${id}`}
                                                marginLeft={[5, 5, 0, 0, 0]}
                                                marginRight={[5, 5, 12, 6, 6]}
                                            >
                                                <NewButton
                                                    onClick={() => {
                                                        setActiveMenu(menu);
                                                        isMobile &&
                                                            setActiveIndexes(
                                                                getOnlineProductCategoriesActiveIndexes(
                                                                    menu.onlineProductCategories
                                                                )
                                                            );
                                                    }}
                                                    height="100%"
                                                    variant="link"
                                                >
                                                    <Flex
                                                        direction="column"
                                                        borderBottom={onlineMenu.id === id ? "2px solid" : "none"}
                                                        borderColor={onlineMenu.id === id ? "green.600" : "#FFFFFF00"}
                                                    >
                                                        <Flex mb={4} align="center">
                                                            <Header
                                                                size="md"
                                                                color="blackAlpha"
                                                                fontWeight="bold"
                                                                mr={2}
                                                                mb={0}
                                                            >
                                                                {name}
                                                            </Header>
                                                            {hasNoActiveMenus &&
                                                                menu.eatingOptions.map(eatingOption => (
                                                                    <Box
                                                                        key={eatingOption}
                                                                        as={eatingOptionIcons[eatingOption]}
                                                                        size="16px"
                                                                        color="blackAlpha"
                                                                    />
                                                                ))}
                                                        </Flex>
                                                        <Text mb={1}>{activeHoursText}</Text>
                                                    </Flex>
                                                </NewButton>
                                            </Box>
                                        );
                                    })}
                                </Box>
                                {menuScrollState.scrollLeft <
                                    menuScrollState.scrollWidth - menuScrollState.offsetWidth - 16 && (
                                    <PseudoBox
                                        _hover={{ cursor: "pointer" }}
                                        position="absolute"
                                        right={[0, 0, 6, 12, 24]}
                                        paddingX={3}
                                        zIndex={1}
                                        height="63px"
                                        background="linear-gradient(-90deg, white, #FFFFFF00 200%)"
                                        onClick={() => {
                                            handleScrollTo(menuRef, menuScrollState, "right");
                                        }}
                                    >
                                        <Box color="primaryDark" as={FaChevronRight} height="100%" />
                                    </PseudoBox>
                                )}
                            </Box>
                            <Box
                                overflowX="auto"
                                display="flex"
                                id="hidden-scroll"
                                ref={categoryRef}
                                onScroll={() => {
                                    setCategoryScrollState((currState: any) => ({
                                        ...currState,
                                        scrollLeft: categoryRef.current.scrollLeft
                                    }));
                                }}
                            >
                                {categoryScrollState.scrollLeft > 16 && (
                                    <PseudoBox
                                        _hover={{ cursor: "pointer" }}
                                        position="absolute"
                                        left={[0, 0, 6, 12, 24]}
                                        paddingX={3}
                                        zIndex={1}
                                        height="30px"
                                        background="linear-gradient(90deg, white, #FFFFFF00 200%)"
                                        onClick={() => {
                                            handleScrollTo(categoryRef, categoryScrollState, "left");
                                        }}
                                    >
                                        <Box color="primaryDark" as={FaChevronLeft} height="100%" />
                                    </PseudoBox>
                                )}
                                <Box display="flex" flexWrap="nowrap">
                                    <OnlineOrderCategoriesNavigation
                                        onlineProductCategories={onlineMenu.onlineProductCategories}
                                        small={matches.small}
                                    />
                                </Box>
                                {categoryScrollState.scrollLeft <
                                    categoryScrollState.scrollWidth - categoryScrollState.offsetWidth - 16 && (
                                    <PseudoBox
                                        _hover={{ cursor: "pointer" }}
                                        position="absolute"
                                        right={[0, 0, 6, 12, 24]}
                                        paddingX={3}
                                        zIndex={1}
                                        height="30px"
                                        background="linear-gradient(-90deg, white, #FFFFFF00 200%)"
                                        onClick={() => {
                                            handleScrollTo(categoryRef, categoryScrollState, "right");
                                        }}
                                    >
                                        <Box color="primaryDark" as={FaChevronRight} height="100%" />
                                    </PseudoBox>
                                )}
                            </Box>
                        </Box>
                    )
                }
            </Media>
        </Flex>
    );
});
