import React, { useEffect } from "react";
import { FaRegCalendarAlt } from "@react-icons/all-files/fa/FaRegCalendarAlt";
import { useParams } from "react-router-dom";
import { RemoveScroll } from "react-remove-scroll";

import { getCartTotalPrice, isCartFinished, calculateDeliveryFee, formatFinancialNumbers } from "Utils";
import {
    useOnline,
    EatingPreference,
    useLanguage,
    isEatingPreferenceCatering,
    usePos,
    BrowseOptions,
    getCartProductsQuantity,
    Languagekey
} from "Providers";
import { Settings } from "Types";
import { LanguageHeader } from "Components";
import { Box, Flex, Text, NewButton, NewDivider, Button } from "Atoms";
import { CloseButton } from "Molecules";
import { useShouldClearOnlineCart } from "../hooks";
import { getMinOrderTotalAmount, shouldDisableOnlineOrderCart } from "OnlineUtils";
import { OnlineCustomerCartList } from "OnlineComponents";
import { Slider } from "Organisms";
import { useOnlineStore, useOrderWindowsStore, useQoplaStore } from "Stores";

type StickyCartSummaryButtonProps = {
    onOpenCart: () => void;
    totalPrice: number;
    openPickupModal: () => void;
    isHomeDeliveryOrCatering: boolean;
    subTotal: number;
    minOrderTotalAmount: number;
    leftTextKey: Languagekey;
    hasActiveMenus: boolean;
    hasProductsInCart: boolean;
    shouldDim: boolean;
    isCatering: boolean;
    browseMode: Record<BrowseOptions, boolean>;
    cartProductLength: number;
};

const StickyCartSummaryButton: React.FC<StickyCartSummaryButtonProps> = ({
    onOpenCart,
    totalPrice,
    subTotal,
    isHomeDeliveryOrCatering,
    minOrderTotalAmount,
    leftTextKey,
    openPickupModal,
    shouldDim,
    isCatering,
    browseMode,
    hasProductsInCart,
    cartProductLength
}) => {
    const { companyLocale } = useQoplaStore();
    const { translate } = useLanguage();
    const {
        orderWindows: [orderWindow]
    } = useOrderWindowsStore();
    const hasNotMetMinHomeDeliveryAmount = isHomeDeliveryOrCatering && subTotal < minOrderTotalAmount;
    const isCheckoutDisabled =
        !orderWindow.cartProducts.length ||
        !isCartFinished(orderWindow.cartProducts) ||
        (!isCatering && browseMode.hasNoActiveMenus) ||
        hasNotMetMinHomeDeliveryAmount ||
        shouldDim;

    const onOpenPickupModal = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        openPickupModal();
    };

    return (
        <Box
            position="fixed"
            right="0"
            bottom="0"
            left="0"
            py={8}
            px={4}
            onClick={() => !isCheckoutDisabled && onOpenCart()}
        >
            <Flex
                alignItems="center"
                bg={!isCheckoutDisabled ? "newPrimary" : "#91AC9D"}
                color={!isCheckoutDisabled ? "newPrimaryFont" : "white"}
                rounded="md"
                p={3}
            >
                <Box>
                    <NewButton
                        p={0}
                        variant="outline"
                        bg={!shouldDim ? "newPrimary" : "green.700"}
                        color={!shouldDim ? "newPrimaryFont" : "white"}
                        opacity={!shouldDim ? "100%" : "40%"}
                        border="none"
                        fontSize="xl"
                        onClick={onOpenPickupModal}
                        isDisabled={shouldDim}
                    >
                        <Box as={FaRegCalendarAlt} />
                    </NewButton>
                </Box>
                <Flex flex="1" justifyContent="space-evenly">
                    <Box>
                        <Text as="span" fontSize="xl" fontWeight="normal">
                            {hasNotMetMinHomeDeliveryAmount
                                ? `${translate(leftTextKey)} ${formatFinancialNumbers(
                                      minOrderTotalAmount - subTotal,
                                      companyLocale
                                  )}`
                                : translate("viewOrder")}
                        </Text>
                    </Box>
                    {!isCheckoutDisabled && (
                        <Box justifySelf="flex-end">
                            <Text as="span" color="inherit" fontSize="xl">
                                {cartProductLength} st | {formatFinancialNumbers(totalPrice, companyLocale)}
                            </Text>
                        </Box>
                    )}
                </Flex>
            </Flex>
        </Box>
    );
};

type ExpandedCartSummaryProps = {
    isProductCommentsEnabled?: boolean;
    params: {
        name: string;
        id: string;
    };
    isHomeDeliveryOrCatering: boolean;
    subTotal: number;
    minOrderTotalAmount: number;
    leftTextKey: Languagekey;
    isCatering: boolean;
    browseMode: Record<BrowseOptions, boolean>;
    onNavigateToCheckout: () => void;
};

const ExpandedCartSummary: React.FC<ExpandedCartSummaryProps> = ({
    isProductCommentsEnabled,
    isHomeDeliveryOrCatering,
    subTotal,
    minOrderTotalAmount,
    leftTextKey,
    isCatering,
    browseMode,
    onNavigateToCheckout
}) => {
    const { getActiveOrderWindow } = useOrderWindowsStore();
    const orderWindow = getActiveOrderWindow();
    const { expandMobileCart, setExpandMobileCart } = useOnlineStore();
    const { translate } = useLanguage();
    const { companyLocale } = useQoplaStore();

    const hasNotMetMinHomeDeliveryAmount = isHomeDeliveryOrCatering && subTotal < minOrderTotalAmount;
    const isCheckoutDisabled =
        !orderWindow?.cartProducts?.length ||
        !isCartFinished(orderWindow?.cartProducts) ||
        hasNotMetMinHomeDeliveryAmount ||
        (!isCatering && browseMode.hasNoActiveMenus);

    return (
        <RemoveScroll enabled={expandMobileCart} removeScrollBar allowPinchZoom>
            <Slider in={expandMobileCart} direction="bottom" style={{ background: "red", zIndex: 10, height: "100%" }}>
                <Flex direction="column" position="fixed" bottom="0" left="0" width="100%" bg="white">
                    <Flex flex="0 1 auto" height="auto" p={4}>
                        <LanguageHeader tid="yourOrder" m="0" />
                        <CloseButton onClick={() => setExpandMobileCart(!expandMobileCart)} />
                    </Flex>
                    <NewDivider color="gray.400" />
                    <Flex flex="1 1 auto" px={4} pt={4} pb={8} overflow="auto">
                        {!!orderWindow?.cartProducts?.length && (
                            <OnlineCustomerCartList
                                appliedDiscount={orderWindow?.discount}
                                isProductCommentsEnabled={isProductCommentsEnabled}
                            />
                        )}
                    </Flex>
                    <Text px={"1rem"} color="gray.600" textAlign={"center"}>
                        {translate("allergyText")}
                    </Text>
                    <Flex flex="0 1 60px" p="0 1rem 2rem 1rem">
                        <Button
                            height="56px"
                            bg="newPrimary"
                            color="newPrimaryFont"
                            fullWidth
                            _hover={{}}
                            size="lg"
                            isDisabled={isCheckoutDisabled}
                            onClick={onNavigateToCheckout}
                        >
                            <Text as="span" fontSize="xl" fontWeight="normal">
                                {hasNotMetMinHomeDeliveryAmount
                                    ? `${translate(leftTextKey)} ${formatFinancialNumbers(
                                          minOrderTotalAmount - subTotal,
                                          companyLocale
                                      )}`
                                    : translate("toCheckout")}
                            </Text>
                        </Button>
                    </Flex>
                </Flex>
            </Slider>
        </RemoveScroll>
    );
};

type Props = {
    openPickupModal: () => void;
    upsellCategory: any;
    outOfStockIds: any;
    productsIdsAndQuantity: any;
    isProductCommentsEnabled?: boolean;
    hasActiveMenus: boolean;
    onNavigateToCheckout: () => void;
    setUpsellModalState: React.Dispatch<
        React.SetStateAction<{
            open: boolean;
            hasShown: boolean;
        }>
    >;
    upsellModalState: {
        open: boolean;
        hasShown: boolean;
    };
};

type Params = { name: string; id: string };

const OnlineOrderStickyMobileCart: React.FC<Props> = ({
    openPickupModal,
    isProductCommentsEnabled,
    hasActiveMenus,
    onNavigateToCheckout,
    upsellModalState,
    setUpsellModalState,
    ...rest
}) => {
    const params = useParams<Params>();
    const { expandMobileCart, setExpandMobileCart } = useOnlineStore();
    const { getActiveOrderWindow } = useOrderWindowsStore();
    const orderWindow = getActiveOrderWindow();

    useShouldClearOnlineCart();

    const {
        foodOptions: { eatingPreference, deliveryInformation },
        browseMode
    } = useOnline();
    const { menus } = usePos();
    const { selectedShop: shop } = useQoplaStore();

    const settings = shop?.settings as Settings;
    const {
        homeDeliverySettings: { minAmountFreeDelivery }
    } = settings;

    const _isCatering = isEatingPreferenceCatering(eatingPreference);
    const isHomeDelivery = eatingPreference === EatingPreference.HOME_DELIVERY;
    const isCateringDelivery = eatingPreference === EatingPreference.CATERING_DELIVERY;
    const isHomeDeliveryOrCatering = isHomeDelivery || _isCatering;
    const isDeliveryAndHasDeliveryInfo = (isHomeDelivery || isCateringDelivery) && !!deliveryInformation;

    const minOrderTotalAmount = getMinOrderTotalAmount(eatingPreference, settings);
    const subTotal = getCartTotalPrice(orderWindow?.cartProducts);
    const addedDeliveryFee = isDeliveryAndHasDeliveryInfo
        ? calculateDeliveryFee(subTotal, minAmountFreeDelivery, deliveryInformation.fee)
        : 0;
    const totalPrice = subTotal + addedDeliveryFee;

    const cartProductsAmount = getCartProductsQuantity(orderWindow?.cartProducts);
    const shouldDim = shouldDisableOnlineOrderCart(menus, browseMode);
    const leftTextKey = _isCatering ? "restForCatering" : "restForHomeDelivery";

    useEffect(() => {
        let id: number;
        if (rest.upsellCategory && expandMobileCart && !upsellModalState.hasShown) {
            id = window.setTimeout(() => {
                setUpsellModalState({ open: true, hasShown: true });
            }, 400);
        }
        return () => window.clearTimeout(id);
    }, [expandMobileCart]);

    const onOpenCart = () => {
        if (cartProductsAmount === 0 || shouldDim) return;
        setExpandMobileCart(!expandMobileCart);
    };

    return (
        <>
            <ExpandedCartSummary
                params={{ name: params.name!, id: params.id! }}
                isProductCommentsEnabled={isProductCommentsEnabled}
                isHomeDeliveryOrCatering={isHomeDeliveryOrCatering}
                subTotal={subTotal}
                minOrderTotalAmount={minOrderTotalAmount}
                leftTextKey={leftTextKey}
                isCatering={_isCatering}
                browseMode={browseMode}
                onNavigateToCheckout={onNavigateToCheckout}
            />
            <StickyCartSummaryButton
                onOpenCart={onOpenCart}
                openPickupModal={openPickupModal}
                totalPrice={totalPrice}
                subTotal={subTotal}
                minOrderTotalAmount={minOrderTotalAmount}
                isHomeDeliveryOrCatering={isHomeDeliveryOrCatering}
                leftTextKey={leftTextKey}
                hasActiveMenus={hasActiveMenus}
                isCatering={_isCatering}
                browseMode={browseMode}
                shouldDim={shouldDim}
                hasProductsInCart={cartProductsAmount > 0}
                cartProductLength={cartProductsAmount}
            />
        </>
    );
};

export default OnlineOrderStickyMobileCart;
