import React, { useEffect } from "react";
import { useFormikContext } from "formik-next";

import { TranslateFunc, useTheme } from "Providers";
import { NewGrid as Grid, Flex, Box, Button, Input } from "Atoms";
import { GiftCardFormValues } from "../OnlineOrderGiftCard";
import { ValidateStep } from "Organisms";

const customInputStyles = {
    backgroundColor: "gray.300",
    width: "175px",
    lineHeight: "3rem",
    height: "4rem",
    fontWeight: "800",
    paddingRight: "0.5rem"
};

type Props = {
    minAmount?: number;
    translate: TranslateFunc;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

type ButtonValues = { small: number; medium: number; large: number };

export const OnlineOrderGiftCardValue: React.FC<Props> = ({ minAmount = 0, translate, setFieldValue }) => {
    const { validateForm, errors, values } = useFormikContext<GiftCardFormValues>();
    const { colors } = useTheme();

    const onSetMoneyValue = (event: React.MouseEvent<HTMLButtonElement>) => {
        const monetryAmount = Number(event.currentTarget.value);
        setFieldValue(event.currentTarget.name, monetryAmount);
    };

    const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const monetryAmount = Number(event.target.value);
        setFieldValue("recipient.customValue", monetryAmount);
    };

    const mediumNumber = () => {
        const minValue = minAmount ? minAmount : 250;
        const maxValue = minAmount ? minAmount + 1000 : 1250;
        return (minValue + maxValue) / 2;
    };

    const buttonValues: ButtonValues = {
        small: minAmount ? minAmount : 250,
        medium: mediumNumber(),
        large: minAmount ? minAmount + 1000 : 1250
    };

    const commonButtonStyles = {
        backgroundColor: "newPrimary",
        color: "newPrimaryFont",
        _hover: { backgroundColor: "newPrimaryHover" },
        cursor: "pointer",
        marginRight: "0.5rem"
    };

    const hasBorderErrors = (colour: string) => {
        return {
            border: "2px",
            borderColor: errors.recipient?.customValue ? colors.red["500"] : colour,
            borderStyle: "solid"
        };
    };

    useEffect(() => {
        validateForm();
    }, []);

    return (
        <Grid templateRows="repeat(2, minmax(50px, min-content))" alignItems="center" justifyContent="center">
            <ValidateStep>
                {({ setValidatedStep }) => {
                    if (errors.recipient?.customValue) {
                        setValidatedStep(false);
                    } else {
                        setValidatedStep(true);
                    }
                    return (
                        <>
                            <Flex mt={6} mb={6} marginLeft="auto" marginRight="auto">
                                {values.recipient.customValue === 0 ? (
                                    <Input
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            fontSize: "1rem"
                                        }}
                                        transition="font-size 300ms ease-in"
                                        mt={3}
                                        _focus={{ ...hasBorderErrors(colors.blue["500"]) }}
                                        onChange={onChangeValue}
                                        value=""
                                        placeholder={`${translate("chooseAmount")}...`}
                                        {...customInputStyles}
                                        textAlign="center"
                                        type="number"
                                        min="0"
                                        autoComplete={"off"}
                                    />
                                ) : (
                                    <Input
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            fontSize: "2.5rem"
                                        }}
                                        mt={3}
                                        _focus={{ ...hasBorderErrors(colors.blue["500"]) }}
                                        {...hasBorderErrors(colors.gray["300"])}
                                        onChange={onChangeValue}
                                        value={values.recipient.customValue}
                                        {...customInputStyles}
                                        textAlign="center"
                                        type="number"
                                        min="0"
                                        autoComplete={"off"}
                                    />
                                )}

                                <Box as="h2" color="gray.600" marginLeft="0.5rem">
                                    kr
                                </Box>
                            </Flex>

                            <Flex>
                                <Button
                                    type="button"
                                    name="recipient.customValue"
                                    value={buttonValues.small}
                                    onClick={onSetMoneyValue}
                                    {...commonButtonStyles}
                                >
                                    {`${buttonValues.small} kr`}
                                </Button>
                                <Button
                                    type="button"
                                    name="recipient.customValue"
                                    value={buttonValues.medium}
                                    onClick={onSetMoneyValue}
                                    {...commonButtonStyles}
                                >
                                    {`${buttonValues.medium} kr`}
                                </Button>
                                <Button
                                    type="button"
                                    name="recipient.customValue"
                                    value={buttonValues.large}
                                    onClick={onSetMoneyValue}
                                    {...commonButtonStyles}
                                >
                                    {`${buttonValues.large} kr`}
                                </Button>
                            </Flex>
                        </>
                    );
                }}
            </ValidateStep>
        </Grid>
    );
};
