import React, { useState } from "react";
import { ErrorMessage, useField } from "formik-next";

import { InputGroup, InputRightElement } from "Molecules";
import { EatingPreference, useLanguage } from "Providers";
import { CustomerPickupFormComponent } from ".";
import { CustomCateringDate, Settings } from "Types";
import { useQoplaStore } from "Stores";
import { useImperativeQuery } from "Hooks";
import { GET_DELIVERY_INFORMATION } from "GraphQLQueries";
import { Box, Button, FormErrorMessage, FormLabel, Input } from "Atoms";
import { newMothershipApolloClient } from "../../../../../../graphql/clients";

export const PostCodeField: React.FC<CustomerPickupFormComponent> = ({ values, setFieldValue, setFieldError }) => {
    const [isLoading, setIsLoading] = useState(false);

    const { translate } = useLanguage();
    const { selectedShop, companyLocale } = useQoplaStore();
    const { cateringSettings, homeDeliverySettings } = selectedShop?.settings as Settings;

    const getDeliveryInformation = useImperativeQuery(GET_DELIVERY_INFORMATION, { client: newMothershipApolloClient });

    const [field] = useField("postCode");

    const resetDeliveryInformationAndReturnError = () => {
        setFieldValue("tmpDeliveryInformation", null, false);
        return translate("restaurantDoesNotDeliver");
    };

    const fetchDeliveryInformationData = async (value: string, isCateringDelivery: boolean) => {
        const { data } = await getDeliveryInformation({
            shopId: selectedShop?.id,
            postalCode: value,
            isCateringDelivery
        });

        return data?.getDeliveryInformation;
    };

    const setTmpDeliveryInformation = (getDeliveryInformation: any) => {
        const deliveryFee: number = getDeliveryInformation.deliveryFee;
        const customCateringDates: CustomCateringDate[] | [] = getDeliveryInformation.customCateringDates;

        setFieldValue(
            "tmpDeliveryInformation",
            {
                deliveryFee: Math.floor(deliveryFee),
                customCateringDates,
                pickupAddress: getDeliveryInformation.pickupAddress,
                allowPickup: getDeliveryInformation.allowPickup
            },
            true
        );
    };

    const validateCateringPostCodeField = async (value: string) => {
        const hasDeliveryAreas = !!cateringSettings.deliveryAreas?.length;

        if (!hasDeliveryAreas) {
            return resetDeliveryInformationAndReturnError();
        }

        const deliveryInformationData = await fetchDeliveryInformationData(value, true);

        if (!deliveryInformationData) {
            return resetDeliveryInformationAndReturnError();
        }

        setTmpDeliveryInformation(deliveryInformationData);

        return null;
    };

    const validateHomeDeliveryPostCodeField = async (value: string) => {
        const deliveryInformationData = await fetchDeliveryInformationData(value, false);

        if (!deliveryInformationData) {
            return resetDeliveryInformationAndReturnError();
        }

        setTmpDeliveryInformation(deliveryInformationData);

        return null;
    };

    const getErrorMessage = async (value: string) => {
        if (value.length !== 5) {
            return translate("mustBeFiveDigits");
        }

        if (!/^(?=.*[0-9])[ 0-9]+$/.test(value)) {
            return translate("wrongFormat");
        }

        if (values.eatingPreference === EatingPreference.CATERING) {
            return await validateCateringPostCodeField(value);
        } else if (values.eatingPreference === EatingPreference.HOME_DELIVERY) {
            return await validateHomeDeliveryPostCodeField(value);
        }

        return null;
    };

    const validatePostCodeField = async (value: string) => {
        try {
            const errorMessage = await getErrorMessage(value);

            if (errorMessage) {
                setFieldValue("tmpDeliveryInformation", null, false);
                setFieldValue("pickupDate", "", false);
                setFieldValue("pickupTime", "", false);

                setFieldError("postCode", errorMessage);
            }
        } catch (error) {
            console.log(`Error when validating post code field: ${error}`);
        }
    };

    const onButtonClick = async () => {
        setIsLoading(true);
        setFieldValue("tmpPrevPostCode", values.postCode, true);
        await validatePostCodeField(values.postCode);
        setIsLoading(false);
    };

    return (
        <Box>
            <FormLabel>{translate("postCode")}</FormLabel>
            <InputGroup size="lg" w="full">
                <Input
                    {...field}
                    placeholder={homeDeliverySettings.postalCodePlaceholder || translate("postCode")}
                    maxLength={5}
                    pr="4.5rem"
                />
                <InputRightElement w="7.5rem">
                    <Button
                        size="sm"
                        isLoading={isLoading}
                        type="button"
                        onClick={onButtonClick}
                        isDisabled={values.postCode.length !== 5}
                        _focus={{ boxShadow: "outline" }}
                    >
                        {translate("continueAlt")}
                    </Button>
                </InputRightElement>
            </InputGroup>
            <ErrorMessage name="postCode" component={FormErrorMessage} />
        </Box>
    );
};
