import moment from "moment";

import { CustomCateringDate } from "Types";
import { momentDayNoToBackendDay } from "Utils";

const getCateringText = (
    customCateringDates: CustomCateringDate[],
    customSpecificCateringDates: CustomCateringDate[],
    excludedDates: moment.Moment[],
    pickupDate: string
) => {
    if (!pickupDate) {
        return "";
    }

    if (!!customSpecificCateringDates && !!customSpecificCateringDates.length) {
        const foundMessage = customSpecificCateringDates.find(({ days }) => {
            return days.some(day => {
                const momentDay = moment(day);
                const oneWeekBefore = momentDay.clone().subtract(1, "weeks").format("YYYY-MM-DD");
                return momentDay.format("YYYY-MM-DD") === pickupDate || oneWeekBefore === pickupDate;
            });
        })?.message;

        return foundMessage || "";
    }

    if (!!excludedDates.length) {
        //@ts-ignore
        const pickupDateToDayName = momentDayNoToBackendDay[moment(pickupDate).day()];
        const foundMessage = customCateringDates.find(({ days }) => days.includes(pickupDateToDayName))?.message;
        return foundMessage || "";
    }
};

export { getCateringText };
