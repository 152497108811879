import React from "react";
import { useMedia } from "react-media";

import { NewButton, Box } from "Atoms";
import { OnlineProductCategories, PartnerPromotionImageType } from "Types";
import { useOnlineStore } from "Stores";
import { GLOBAL_BREAK_POINTS } from "Constants";
import { OnlinePromotionCategory } from "OnlineComponents";
import { useQoplaStore } from "Stores";

type Props = {
    onlineProductCategories: OnlineProductCategories;
    small?: boolean;
};

export const OnlineOrderCategoriesNavigation: React.FC<Props> = React.memo(({ onlineProductCategories, small }) => {
    const { handleSetActiveIndexes, activeIndexes, activeMenuProdCatId, setActiveMenuProdCatId } = useOnlineStore();
    const { selectedShop } = useQoplaStore();
    const isMediumScreen = useMedia({ query: GLOBAL_BREAK_POINTS.MD });

    const onMenuProdCatClick = (menuId: string, ref: React.RefObject<HTMLElement>, idx: number) => {
        // 1. Check ref element
        const node = ref.current;
        // 2. Get offset from top of page
        const elementPlacement = node?.offsetTop;
        // 3. If present scroll to it
        if (elementPlacement) {
            // remove 128 from offset to show category name as well
            // if this is not done category is not shown
            const additionalOffset = isMediumScreen ? 75 : 128;
            window.scroll(0, elementPlacement - additionalOffset);
        }

        setActiveMenuProdCatId(menuId);
        handleSetActiveIndexes(idx);
    };

    const shopHasPartnerPromotion = !!selectedShop?.settings.partnerPromotionId;
    return (
        <>
            {onlineProductCategories.map(({ name, id, ref, partnerPromotionImageType }, idx: number) => {
                const isActive =
                    (!small && activeMenuProdCatId === id) || (small && (activeIndexes as number[]).includes(idx));
                const isPromotedCategory =
                    partnerPromotionImageType === PartnerPromotionImageType.CATEGORY_AND_PRODUCTS;
                return (
                    <Box key={id} id={name} marginLeft={[5, 5, 0, 0, 0]} marginRight={[5, 5, 12, 6, 6]}>
                        <NewButton
                            themeColor={isActive ? "green" : "gray"}
                            variant="link"
                            fontWeight="normal"
                            onClick={() => {
                                onMenuProdCatClick(id, ref, idx);
                            }}
                            paddingTop={2}
                            paddingBottom={2}
                            minWidth="auto"
                        >
                            {name}
                            {isPromotedCategory && shopHasPartnerPromotion && (
                                <OnlinePromotionCategory
                                    categoryType={PartnerPromotionImageType.CATEGORY_NAVIGATION}
                                    h="19px"
                                    mt="-2px"
                                    position={"static"}
                                />
                            )}
                        </NewButton>
                    </Box>
                );
            })}
        </>
    );
});
