import React, { useEffect } from "react";
import { FaCreditCard } from "@react-icons/all-files/fa/FaCreditCard";
import { Field } from "formik-next";

import { SwishLogoPng } from "Assets";
import { PaymentMethod as PaymentMethodType } from "Constants";
import { useLanguage, useOnline } from "Providers";
import { Box, Flex, Text, Button } from "Atoms";
import { FormInput } from "Molecules";
import { GiftCardFormValues } from "../OnlineOrderGiftCard";
import { formatFinancialNumbers } from "Utils";
import { PaymentMethod } from "../../../../../pages/checkout/components/cards/OnlineCheckoutFormPaymentCard";
import { PhoneInputWithCountryCode } from "../../../../../shared/components";
import { useQoplaStore } from "Stores";
import { TypeOfServiceDisruption } from "Types";
import { OnlineCheckoutPaymentDisruption } from "../../../../../../customer/pages/onlineCheckout/components/OnlineCheckoutFooter/components";

type Props = {
    values: GiftCardFormValues;
    isSubmitting: boolean;
    isValid: boolean;
    isDirty: boolean;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

export const OnlineOrderGiftCardPaymentMethod: React.FC<Props> = ({ values, isValid, isSubmitting, setFieldValue }) => {
    const { translate } = useLanguage();
    const { companyLocale } = useQoplaStore();

    const {
        serviceDisruptions: { isCardServiceDisrupted, isSwishServiceDisrupted }
    } = useOnline();

    const {
        sender: { paymentInformation }
    } = values;

    const paymentMethods = [
        {
            text: "Swish",
            value: PaymentMethodType.SWISH,
            logo: SwishLogoPng
        },
        { text: translate("card"), value: PaymentMethodType.CARD, logo: FaCreditCard }
    ];

    const onPhoneChange = (e: any, setFieldValue: any) => {
        const value = e.target.value;
        setFieldValue("sender.phoneNumber", value);
    };

    const isCardPayment = paymentInformation.paymentMethod === PaymentMethodType.CARD;
    const isSwishPayment = paymentInformation.paymentMethod === PaymentMethodType.SWISH;
    const payButtonDisabled =
        !isValid ||
        isSubmitting ||
        (isCardServiceDisrupted && isCardPayment) ||
        (isSwishServiceDisrupted && isSwishPayment);

    useEffect(() => {
        if (isCardServiceDisrupted || isSwishServiceDisrupted) {
            if (isCardServiceDisrupted) {
                setFieldValue("sender.paymentInformation.paymentMethod", PaymentMethodType.SWISH);
            } else {
                setFieldValue("sender.paymentInformation.paymentMethod", PaymentMethodType.CARD);
            }
        }
    }, [isCardServiceDisrupted, isSwishServiceDisrupted]);

    return (
        <>
            <Box pr={1} pl={1}>
                <Field
                    formLabel={translate("name")}
                    placeholder={translate("name")}
                    name="sender.firstName"
                    as={FormInput}
                    size="lg"
                    fullWidth
                    isMandatory
                    wrapperProps={{ w: "100%", mr: 4 }}
                />
                <Field
                    inputLabel={translate("phone")}
                    placeholder="467XXXXXXXX"
                    name="sender.phoneNumber"
                    type="number"
                    phoneNumber={values.sender.phoneNumber}
                    as={PhoneInputWithCountryCode}
                    size="lg"
                    fullWidth
                    onChange={(e: any) => onPhoneChange(e, setFieldValue)}
                />
                <Field
                    formLabel={translate("mail")}
                    placeholder={translate("mail")}
                    name="sender.email"
                    type="email"
                    as={FormInput}
                    size="lg"
                    fullWidth
                    isMandatory
                    clearable
                />

                <Flex height="auto" direction={["column", "row"]} justify="center" mb={5}>
                    {paymentMethods.map(paymentMethod => {
                        const cardPaymentIsDisabled =
                            isCardServiceDisrupted && paymentMethod.value === PaymentMethodType.CARD;
                        const swishPaymentIsDisabled =
                            isSwishServiceDisrupted && paymentMethod.value === PaymentMethodType.SWISH;
                        return (
                            <PaymentMethod
                                key={paymentMethod.value}
                                paymentMethod={paymentMethod}
                                setFieldValue={(_, value: PaymentMethodType) =>
                                    setFieldValue("sender.paymentInformation.paymentMethod", value)
                                }
                                paymentInformation={paymentInformation}
                                isDisabled={cardPaymentIsDisabled || swishPaymentIsDisabled}
                            />
                        );
                    })}
                </Flex>
                {isCardServiceDisrupted && (
                    <OnlineCheckoutPaymentDisruption disruptionType={TypeOfServiceDisruption.CARD} />
                )}
                {isSwishServiceDisrupted && (
                    <OnlineCheckoutPaymentDisruption disruptionType={TypeOfServiceDisruption.SWISH} />
                )}
                {isCardPayment && <Text fontSize="xl">{translate("enterCardDetailsInNextStep")}</Text>}
                <Flex justifyContent="space-between" alignItems="center" mb={6} fontSize="1.5rem" fontWeight="700">
                    <Text my={0}>{translate("total")}</Text>
                    <Text my={0} textAlign="right">
                        {`${formatFinancialNumbers(values.recipient.customValue, companyLocale)}`}
                    </Text>
                </Flex>
                <Button isDisabled={payButtonDisabled} themeColor="blue" type="submit" fullWidth size="xl" mb={10}>
                    {translate("pay")}
                </Button>
            </Box>
        </>
    );
};
