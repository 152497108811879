import React from "react";
import { Control, useFormState, useWatch } from "react-hook-form";

import { Button, ButtonProps } from "Atoms";
import { useLanguage } from "Providers";
import { OnlinePaymentMethod } from "Types";
import { OnlineCheckoutFormValues } from "../../../OnlineCheckout";
import { shouldDisableSubmitButton } from "../../../onlineCheckoutHelpers";

type Props = {
    control: Control<OnlineCheckoutFormValues>;
    isZeroAmount: boolean;
    formattedTotalOrderPrice: string;
    onClickPay: (paymentMethod: OnlinePaymentMethod) => void;
} & ButtonProps;

export const OnlineCheckoutPaymentButton = ({
    control,
    onClickPay,
    isZeroAmount,
    formattedTotalOrderPrice,
    ...rest
}: Props) => {
    const { translate } = useLanguage();

    // Get the form submission state and errors
    const { isSubmitting, errors } = useFormState({ control });

    const [paymentMethod] = useWatch({ control, name: ["paymentMethod"] });

    const hasErrors = shouldDisableSubmitButton(errors, paymentMethod);

    const buttonText = isZeroAmount ? translate("placeOrder") : `${translate("pay")} ${formattedTotalOrderPrice}`;

    return (
        <Button
            themeColor="blue"
            size="lg"
            h={16}
            rounded="lg"
            fontSize="2xl"
            maxW="container.md"
            fullWidth
            _focus={{ boxShadow: "outline" }}
            isLoading={isSubmitting}
            isDisabled={hasErrors}
            type="submit"
            onClick={() =>
                paymentMethod && !isZeroAmount ? onClickPay(paymentMethod) : onClickPay(OnlinePaymentMethod.NO_CHARGE)
            }
            {...rest}
        >
            {buttonText}
        </Button>
    );
};
