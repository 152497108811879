import moment from "moment";
import { capitalize, flatMap } from "lodash";

import { getTimeStops, getDateStops, addExtraMidnightHours, getHoursAndMinutes } from "Utils";
import { ONLINE_TIME_PICK_INTERVAL, ONLINE_TIME_PICK_INTERVAL_HALF_HOUR, timeConstants, DAYS } from "Constants";
import { ActiveHour, CustomCateringDate, CateringSettings, CateringLastOrderDay } from "Types";
import { EatingPreference, ILanguages } from "Providers";
import { PickupFormEatingPreference, CustomerPickupFormik } from "../OnlineOrderPickupModal";

type TimeStop = {
    text: string;
    value: string;
    key: string;
};
export type TimeStops = TimeStop[];

const getPickupStartDate = (
    eatingSelection: PickupFormEatingPreference,
    { noticeHours, lastOrderDay, noPickupHours, customSpecificCateringDates, customCateringDates }: CateringSettings,
    userLanguage: ILanguages = "sv",
    backendDiff: number
) => {
    const todayMoment = moment().add(backendDiff, "ms").locale(userLanguage);

    if (eatingSelection === EatingPreference.CATERING) {
        const cateringMoment = todayMoment.clone().add(noticeHours, "hours");
        if (!!lastOrderDay && !lastOrderDay.date) {
            const [hours, minutes] = getHoursAndMinutes(lastOrderDay.stoppingHour);
            const lastDayToOrderDate = moment().isoWeekday(lastOrderDay.dayOfWeek).hours(hours).minutes(minutes);

            if (lastDayToOrderDate.isAfter(moment().add(backendDiff, "ms"))) {
                return cateringMoment;
            } else {
                return cateringMoment.add(1, "week");
            }
        } else if (!!customSpecificCateringDates && !!customSpecificCateringDates.length) {
            const flatDays = flatMap(customSpecificCateringDates, ({ days }) => days.map(day => moment(day)));
            return moment.min(flatDays).locale(userLanguage);
        } else if (noPickupHours) {
            const pickupStartDate = cateringMoment.clone().set({ hour: 10, minute: 0 }); // We have this hardcoded to 10:00

            const adjustedStartPickupDate = cateringMoment.isAfter(pickupStartDate)
                ? pickupStartDate.add(1, "day")
                : pickupStartDate;

            return adjustedStartPickupDate;
        } else {
            return cateringMoment;
        }
    }

    return todayMoment;
};

const getPickupTimeStart = (
    isCatering: boolean,
    pickupDate: string,
    activeHour: ActiveHour,
    noticeHours: number,
    backendDiff: number
) => {
    if (isCatering && noticeHours > 0) {
        const shopStartingHourMoment = moment(`${pickupDate} ${activeHour.startingHour}`, "YYYY-MM-DD HH:mm");
        const addedNoticeHours = moment().add(backendDiff, "ms").add(noticeHours, "hours");

        const startingHour = addedNoticeHours.isBefore(shopStartingHourMoment)
            ? shopStartingHourMoment
            : addedNoticeHours;

        return startingHour.format("HH:mm");
    } else {
        return activeHour.startingHour;
    }
};

export const getSpecificCateringDatePickupStops = (
    customSpecificCateringDates: CustomCateringDate[],
    userLanguage: ILanguages
) => {
    const customSpecificCateringDays = flatMap(customSpecificCateringDates, ({ days }) => days).sort(
        (a, b) => moment(a).unix() - moment(b).unix()
    );

    return customSpecificCateringDays.map(day => {
        const momentDay = moment(day).locale(userLanguage);
        return {
            text: capitalize(momentDay.format("dddd DD/MM")),
            value: momentDay.format("YYYY-MM-DD"),
            disabled: false
        };
    });
};

const getPickupStops = (
    activeHours: ActiveHour[],
    startDate: moment.Moment,
    onlyAsap: boolean,
    userLanguage: ILanguages,
    showDisabledDates: boolean,
    amountOfDays: number = 7,
    excludedDays?: moment.Moment[]
) => {
    if (onlyAsap) {
        const todayText = userLanguage === "en" ? "Today" : "Idag";
        return [{ text: todayText, value: startDate.format("YYYY-MM-DD"), disabled: false }];
    }
    const dateStops = getDateStops(activeHours, startDate, userLanguage, showDisabledDates, amountOfDays, excludedDays);
    return dateStops.map(day => ({
        text: day.dateText,
        value: day.date,
        disabled: day.disabled
    }));
};
const getCustomerTimeStops = (
    activeHour: ActiveHour | undefined,
    values: CustomerPickupFormik,
    onlyAsap: boolean,
    dateIsToday: boolean,
    noticeHours: number,
    onlineOrderPickupTimesPostpone: number[],
    userLanguage: ILanguages = "sv",
    activeHours: ActiveHour[],
    isLessThanFifteenMin: boolean,
    backendDiff: number,
    isAlwaysAllowPostponePickupTime: boolean
): { key: string; text: string; value: string; dataObject: string }[] => {
    if (!activeHour) {
        // check if there are any midnight hours
        const t = addExtraMidnightHours(
            values.pickupDate,
            activeHours,
            values.eatingPreference == "HOME_DELIVERY"
                ? ONLINE_TIME_PICK_INTERVAL_HALF_HOUR
                : ONLINE_TIME_PICK_INTERVAL,
            moment().add(30, "minutes").add(backendDiff, "ms")
        );
        if (t?.length > 0) {
            return t.map(time => {
                const timeText = time.format("HH:mm");
                return {
                    key: time.format("YYYYMMDDHHmm"),
                    text: timeText,
                    value: timeText,
                    dataObject: time.format("YYYYMMDD")
                };
            });
        } else {
            return [];
        }
    } else {
        const timeTextKeys = {
            en: {
                soon: "As soon as possible",
                earliest: "Earliest"
            },
            sv: {
                soon: "Snarast",
                earliest: "Tidigast"
            }
        };
        const soonText = timeTextKeys[userLanguage].soon;
        const earliestText = timeTextKeys[userLanguage].earliest;
        const todayName = moment(values.pickupDate + " 06:00")
            .locale(userLanguage)
            .format("dddd");

        const isCatering = values.eatingPreference === EatingPreference.CATERING;
        const startingHour = getPickupTimeStart(isCatering, values.pickupDate, activeHour, noticeHours, backendDiff);

        const base =
            !onlyAsap || isCatering
                ? getTimeStops(
                      startingHour,
                      activeHour.stoppingHour,
                      values.pickupDate,
                      values.eatingPreference == "HOME_DELIVERY"
                          ? ONLINE_TIME_PICK_INTERVAL_HALF_HOUR
                          : ONLINE_TIME_PICK_INTERVAL,
                      moment().add(30, "minutes").add(backendDiff, "ms"),
                      activeHours
                  )
                : [];

        const b = base.map((time, idx) => {
            const t = time.format("HH:mm");
            const text = isCatering || !onlineOrderPickupTimesPostpone.length ? "" : `${earliestText} `;
            let timeText = dateIsToday && (isAlwaysAllowPostponePickupTime || idx < 2) ? `${text}${t}` : t;
            const dayName = time.locale(userLanguage).format("dddd");

            if (dayName !== todayName) {
                timeText += ` (${capitalize(dayName)})`;
            }

            return {
                key: time.format("YYYYMMDDHHmm"),
                text: timeText,
                value: t,
                dataObject: time.format("YYYYMMDD")
            };
        });

        return dateIsToday && !isLessThanFifteenMin && !isCatering
            ? [
                  { key: soonText, text: soonText, value: timeConstants.ASAP, dataObject: moment().format("YYYYMMDD") },
                  ...b
              ]
            : b;
    }
};

const cateringExcludedDates = (
    customCateringDates: CustomCateringDate[],
    customSpecificCateringDates: CustomCateringDate[],
    lastOrderDay: CateringLastOrderDay | null,
    startDate: moment.Moment
) => {
    if (!!customCateringDates.length) {
        const flatDays = flatMap(customCateringDates, ({ days }) => days);
        const excludedDayNames = DAYS.filter(day => !flatDays.includes(day));

        let dayCount = 0;
        let momentDate = startDate.clone().locale("en");
        let excludedCateringDates: moment.Moment[] = [];

        while (dayCount <= 9) {
            const dayName = momentDate.format("dddd").toUpperCase();
            if (excludedDayNames.includes(dayName)) {
                excludedCateringDates.push(momentDate.clone());
            }
            // check if there are last order day constraints
            if (!!lastOrderDay?.dayOfWeek) {
                const [hours, minutes] = getHoursAndMinutes(lastOrderDay.stoppingHour);
                const lastDayToOrderDate = moment().isoWeekday(lastOrderDay.dayOfWeek).hours(hours).minutes(minutes);
                if (lastDayToOrderDate.isBefore(startDate)) {
                    lastDayToOrderDate.add(1, "week");
                }
                if (momentDate.isBefore(lastDayToOrderDate)) {
                    excludedCateringDates.push(momentDate.clone());
                }
            }
            dayCount++;
            momentDate.add(1, "day");
        }

        return excludedCateringDates;
    }
    if (!!customSpecificCateringDates && !!customSpecificCateringDates.length) {
        const flatDays = flatMap(customSpecificCateringDates, ({ days }) => days.map(day => moment(day)));
        const _shouldIncludeToday = !flatDays.find(date => moment().isSame(date, "day"));

        let dayCount = 0;
        let momentDate = startDate.clone();
        let excludedCateringDates: moment.Moment[] = [];

        while (dayCount < 9) {
            momentDate.add(1, "day");

            const found = !flatDays.find(date => momentDate.isSame(date, "day"));

            if (found) {
                excludedCateringDates.push(momentDate.clone());
            }
            dayCount++;
        }

        return excludedCateringDates.concat(_shouldIncludeToday ? moment() : []);
    } else {
        return [];
    }
};

export { getPickupStartDate, getCustomerTimeStops, getPickupStops, getPickupTimeStart, cateringExcludedDates };
