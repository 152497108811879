import React from "react";
import { FaFileInvoiceDollar } from "@react-icons/all-files/fa/FaFileInvoiceDollar";

import { Box, ButtonProps, Stack, StackProps, Text } from "Atoms";
import { OnlinePaymentMethod } from "Types";
import { useLanguage } from "Providers";
import { OnlineCheckoutGenericPaymentButton } from "./OnlineCheckoutGenericPaymentButton";

export const OnlineCheckoutInvoicePaymentContent: React.FC<StackProps> = props => {
    const { translate } = useLanguage();

    return (
        <Stack stretch={2} isInline align="center" {...props}>
            <Text as="span">{translate("invoice")}</Text>
            <Box as={FaFileInvoiceDollar} size={6} />
        </Stack>
    );
};

type Props = {
    onClickPay: (paymentMethod: OnlinePaymentMethod) => void;
} & ButtonProps;

export const OnlineCheckoutInvoicePaymentButton = ({ onClickPay, ...rest }: Props) => (
    <OnlineCheckoutGenericPaymentButton onClick={() => onClickPay(OnlinePaymentMethod.INVOICE)} {...rest}>
        <OnlineCheckoutInvoicePaymentContent />
    </OnlineCheckoutGenericPaymentButton>
);
