import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import moment from "moment";

import { getHoursAndMinutes } from "Utils";
import { ActiveHour, EatingPreference } from "Types";
import { LanguageHeader, LanguageText } from "Components";
import { Flex, Text } from "Atoms";
import { useOnline } from "Providers";

const CLOSING_TOAST_ID = "toast-closingWarning";

const showWarningToast = (
    closingHourMs: moment.Moment,
    shopActiveHour: ActiveHour,
    isClosingPeriod: boolean,
    isKitchenClosed: boolean
) => {
    const lastTimeToOrderFormatted: string = moment(+closingHourMs - 900000).format("HH:mm");

    let toastMessage;

    if (isClosingPeriod && !isKitchenClosed) {
        toast.dismiss(CLOSING_TOAST_ID);
        toastMessage = (
            <Flex textAlign="center" flexDirection="column">
                <LanguageHeader tid="note" as="h3" fontSize="xl" margin={0} />
                <Flex justify="center" flexDirection="row" mt={2}>
                    <LanguageText tid="lastTimeToOrder" mb={1} />
                    <Text ml={1} mb={1}>
                        {lastTimeToOrderFormatted}
                    </Text>
                </Flex>
                <Flex justify="center" flexDirection="row">
                    <LanguageText tid="closingSoon" />
                    <Text ml={1}>{shopActiveHour.stoppingHour}</Text>
                </Flex>
            </Flex>
        );
    } else if (isKitchenClosed) {
        toast.dismiss(CLOSING_TOAST_ID);
        toastMessage = (
            <Flex textAlign="center" flexDirection="column">
                <LanguageHeader tid="note" as="h3" fontSize="xl" margin={0} />
                <LanguageText tid="kitchenClosed" mb={1} />
            </Flex>
        );
    }
    if (toastMessage) {
        toast.warn(toastMessage, {
            draggable: false,
            autoClose: false,
            toastId: CLOSING_TOAST_ID
        });
    }
};

export const useClosingPeriodNotification = (pickupDate: string, eatingPreference: any, backendDiff: number) => {
    const [showWarning, setShowWarning] = useState({ isClosingPeriod: false, isKitchenClosed: false });
    const [timeLeft, setTimeLeft] = useState<number>();

    const { getTodaysActiveHour } = useOnline();

    const cateringOptions = [
        EatingPreference.CATERING_DELIVERY,
        EatingPreference.CATERING_TAKE_AWAY,
        EatingPreference.CATERING
    ];

    let shopActiveHour = getTodaysActiveHour();

    if (!shopActiveHour) {
        shopActiveHour = { dayOfWeek: "", startingHour: "", stoppingHour: "23:59" };
    }

    const closingHourArr = getHoursAndMinutes(shopActiveHour?.stoppingHour);
    const closingHourMs = moment().hours(closingHourArr[0]).minutes(closingHourArr[1]);

    const calcTimeoutInterval = (timeLeftBeforeClosure: number) => {
        let timeoutInterval;
        if (timeLeftBeforeClosure > 3600000) {
            timeoutInterval = 1800000;
            setShowWarning({ isClosingPeriod: false, isKitchenClosed: false });
        } else if (timeLeftBeforeClosure <= 3600000 && timeLeftBeforeClosure > 1800000) {
            timeoutInterval = 60000;
            setShowWarning({ isClosingPeriod: false, isKitchenClosed: false });
        } else if (timeLeftBeforeClosure <= 1800000 && timeLeftBeforeClosure > 900000) {
            timeoutInterval = 60000;
            setShowWarning({ isClosingPeriod: true, isKitchenClosed: false });
        } else if (timeLeftBeforeClosure <= 900000 && timeLeftBeforeClosure > 0) {
            timeoutInterval = 60000;
            setShowWarning({ isClosingPeriod: false, isKitchenClosed: true });
        } else if (timeLeftBeforeClosure <= 0) {
            timeoutInterval = 0;
            setShowWarning({ isClosingPeriod: false, isKitchenClosed: false });
        }
        return timeoutInterval;
    };

    useEffect(() => {
        setTimeLeft(closingHourMs.diff(moment().add(backendDiff, "ms")));
        toast.dismiss(CLOSING_TOAST_ID);
    }, [pickupDate, shopActiveHour?.stoppingHour]);

    useEffect(() => {
        let timer: any;
        if (pickupDate === moment().format("YYYY-MM-DD") && !cateringOptions.includes(eatingPreference)) {
            const timeInterval = timeLeft ? calcTimeoutInterval(timeLeft) : 10;
            if (timeInterval != 0) {
                timer = setTimeout(() => {
                    const timeLeftBeforeClosure = closingHourMs.diff(moment().add(backendDiff, "ms"));
                    setTimeLeft(timeLeftBeforeClosure);
                }, timeInterval);
            }
        }
        return () => clearTimeout(timer);
    }, [timeLeft, eatingPreference, pickupDate]);

    useEffect(() => {
        if (pickupDate === moment().format("YYYY-MM-DD") && !cateringOptions.includes(eatingPreference)) {
            if (shopActiveHour) {
                showWarningToast(
                    closingHourMs,
                    shopActiveHour,
                    showWarning.isClosingPeriod,
                    showWarning.isKitchenClosed
                );
            }
        } else {
            toast.dismiss(CLOSING_TOAST_ID);
        }
    }, [eatingPreference, pickupDate, shopActiveHour, showWarning]);
};
