import React from "react";

import { useQoplaStore } from "Stores";
import {
    CateringContentCustomerTypeField,
    CateringContentWillDeliverCateringField,
    CateringContentTimeIntervalField,
    CateringContentInfoText
} from "./";
import { CustomerPickupFormComponent, PickupOptions, PostCodeField } from "../../";
import { hasCateringDeliveryAreas } from "../../../utils";
import { getPostCodeHelperText } from "../../../utils/getPostCodeFieldHelperText";
import { useLanguage } from "Providers";
import { Box, FormHelperText } from "Atoms";

export const CateringContentWithDefaultContent: React.FC<CustomerPickupFormComponent> = props => {
    const { values } = props;

    const { selectedShop, companyLocale } = useQoplaStore();
    const { translate } = useLanguage();

    const cateringSettings = selectedShop!.settings!.cateringSettings;
    const hasDeliveryAreas = hasCateringDeliveryAreas(cateringSettings);

    const willDeliverCatering = props.values.willDeliverCatering === "YES";

    const deliveryFee = values.tmpDeliveryInformation?.deliveryFee ?? 0;
    const minAmountFreeDelivery = selectedShop?.settings?.homeDeliverySettings?.minAmountFreeDelivery ?? 0;

    const helperText = getPostCodeHelperText(deliveryFee, minAmountFreeDelivery, companyLocale, translate);

    return (
        <>
            <PickupOptions showDisabledDates={!props.hasOnlyCatering} {...props} />
            <CateringContentCustomerTypeField />
            {hasDeliveryAreas && (
                <>
                    <CateringContentWillDeliverCateringField {...props} />
                    <Box mb={6}>
                        <CateringContentTimeIntervalField {...props} />
                    </Box>
                </>
            )}
            {willDeliverCatering && (
                <Box>
                    <PostCodeField {...props} />
                    {helperText && <FormHelperText>{helperText}</FormHelperText>}
                </Box>
            )}
            <CateringContentInfoText />
        </>
    );
};
