import { CateringSettings } from "Types";

export const hasCateringDeliveryAreas = (cateringSettings: CateringSettings) => {
    const hasOwnDelivery = cateringSettings.deliveryAreas.some(deliverArea => deliverArea.enabled);
    return hasOwnDelivery;
};

export const hasCustomDatesInDeliveryAreas = (cateringSettings: CateringSettings) => {
    const hasCustomDates = cateringSettings.deliveryAreas.some(
        deliveryArea => deliveryArea.enabled && deliveryArea?.customDates?.some(customDate => customDate.days.length)
    );
    return hasCustomDates;
};
