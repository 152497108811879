import React from "react";

import { Box, ButtonProps, Stack, StackProps, Text } from "Atoms";
import { OnlinePaymentMethod } from "Types";
import { useLanguage } from "Providers";
import { Visa, MasterCard, Maestro } from "Assets";
import { OnlineCheckoutGenericPaymentButton } from "./OnlineCheckoutGenericPaymentButton";

export const OnlineCheckoutCardPaymentContent: React.FC<StackProps> = props => {
    const { translate } = useLanguage();

    return (
        <Stack stretch={2} isInline alignItems="center" {...props}>
            <Text m={0}>{translate("card")}</Text>
            <Stack height="auto" isInline stretch={1}>
                <Box as={Visa} />
                <Box as={Maestro} />
                <Box as={MasterCard} />
            </Stack>
        </Stack>
    );
};

type Props = {
    onClickPay: (paymentMethod: OnlinePaymentMethod) => void;
} & ButtonProps;

export const OnlineCheckoutCardPaymentButton = ({ onClickPay, ...rest }: Props) => (
    <OnlineCheckoutGenericPaymentButton onClick={() => onClickPay(OnlinePaymentMethod.CARD)} {...rest}>
        <OnlineCheckoutCardPaymentContent />
    </OnlineCheckoutGenericPaymentButton>
);
