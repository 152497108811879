import React from "react";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik-next";
import moment from "moment";

import {
    PickEatingPreference,
    CateringContent,
    EatHereContent,
    TakeAwayContent,
    HomeDeliveryContent
} from "./components";
import { Modal, ModalBody, ModalHeader, ModalCloseBtn } from "Molecules";
import { Flex, NewButton, Box, Collapsable } from "Atoms";
import { useLanguage, usePos } from "Providers";
import {
    getHoursAndMinutes,
    getShopsActiveDay,
    hasPickupTimeSessionExpired,
    getTimeToConfirm,
    timeToNumber,
    isLessThanFifteenMin
} from "Utils";
import {
    ActiveHour,
    Settings,
    ContactInformation,
    OnlineSettings,
    CateringSettings,
    CustomerType,
    EatingPreference
} from "Types";
import { MIDNIGHT_HOURS, ThirdPartyDeliveryType } from "Constants";
import { getInitialValues, hasCateringDeliveryAreas, cateringExcludedDates } from "./utils";
import { getOnlineBrowseOnlyInfo } from "OnlineUtils";
import { useOnlineStore, useQoplaStore } from "Stores";

export const NO_CATERING_PICKUP_HOURS_DEFAULT_TIME = "10:00";

const hasEatingOption = (eatingOption: string) => (eatingOptions: string[]) => eatingOptions.includes(eatingOption);

export const enabledHomeDelivery = hasEatingOption("HOME_DELIVERY");

export type CustomerPickupFormComponent = {
    onlineActiveHour: ActiveHour | undefined;
    isLessThanFifteenMin: boolean;
    hasOnlyCatering: boolean;
} & FormikProps<CustomerPickupFormik>;

export type PickupFormEatingPreference =
    | Exclude<EatingPreference, EatingPreference.CATERING_TAKE_AWAY | EatingPreference.CATERING_DELIVERY>
    | "";

const toDayName = (date: string) => moment(date).format("dddd");

type Props = {
    open: boolean;
    closeModal: () => void;
};

const formContents = {
    CATERING: CateringContent,
    EAT_HERE: EatHereContent,
    TAKE_AWAY: TakeAwayContent,
    HOME_DELIVERY: HomeDeliveryContent,
    "": () => <div />
};

export type CustomerPickupFormik = {
    eatingPreference: PickupFormEatingPreference;
    pickupDate: string;
    pickupTime: string;
    isEarliest: boolean;
    willDeliverCatering: string;
    postCode: string;
    postAddress: string;
    customerType: string;
    city: string;
    onlineActiveHours: ActiveHour[];
    tmpDeliveryType: string;
    tmpDeliveryInformation: any;
    tmpPrevPostCode: string;
    pickupStartDate: moment.Moment;
    timeInterval: string;
    isQuickServeActive: boolean;
    isAfterMidnight: boolean;
};

export const OnlineOrderPickupModal: React.FC<Props> = ({ closeModal, open }) => {
    const { selectedShop: shop } = useQoplaStore();
    const {
        table,
        handleSetOnlineActiveHours,
        handleSetFoodOptions,
        handleSetTable,
        foodOptions,
        browseMode,
        backendDiff
    } = useOnlineStore();
    const { menus } = usePos();
    const { translate } = useLanguage();

    const headerInfo = getOnlineBrowseOnlyInfo(browseMode);

    const activeHours = shop?.activeHours as ActiveHour[];
    const settings = shop?.settings as Settings;
    const contactInformation = shop?.contactInformation as ContactInformation;
    const onlineSettings = settings?.onlineSettings as OnlineSettings;
    const cateringSettings = settings?.cateringSettings as CateringSettings;
    const shopCity = contactInformation?.city as string;
    const eatingOptionsToUse = table ? onlineSettings?.qrEatingOptions : onlineSettings?.eatingOptions;
    const quickServeTable =
        settings.shopTables?.find(table => table.id === onlineSettings?.qrQuickServe?.qrPickupOptionTableId) ?? null;
    const isQuickServeActive = !!quickServeTable && !!table ? quickServeTable.id === table.id : false;

    function validatePickupTime(this: Yup.TestContext, value?: string) {
        const { parent, createError, path } = this;
        if (!!value) {
            if (parent.eatingPreference === EatingPreference.CATERING) {
                const x = moment().add(cateringSettings.noticeHours, "hours").add(backendDiff, "ms");
                const y = moment(`${parent.pickupDate} ${parent.pickupTime}`, "YYYY-MM-DD HH:mm");
                const diff = x.diff(y);

                if (diff >= 0) {
                    return createError({
                        path,
                        message: `${translate("chooseATimeThatIs")} ${cateringSettings.noticeHours} ${translate(
                            "hoursAhead"
                        )}`
                    });
                }
            }

            if (parent.eatingPreference === EatingPreference.HOME_DELIVERY) {
                if (value === "asap") {
                    return true;
                }

                //if shops works after midnight, we should check if shop was open not current day, but previous
                const daysToSubtract = parent.isAfterMidnight ? 1 : 0;

                const dateInWeekDay = moment(parent.pickupDate)
                    .subtract(daysToSubtract, "d")
                    .locale("en")
                    .format("dddd")
                    .toUpperCase();
                const foundActiveHours = activeHours.find((a: any) => a.dayOfWeek === dateInWeekDay);

                if (foundActiveHours) {
                    const startingHour = moment(foundActiveHours.startingHour, "HH:mm");
                    const stoppingHour = moment(foundActiveHours.stoppingHour, "HH:mm");
                    const chosenHour = moment(value, "HH:mm");

                    const isValidPickUpTime = parent.isAfterMidnight
                        ? chosenHour.isBefore(stoppingHour)
                        : chosenHour.isAfter(startingHour) && chosenHour.isBefore(stoppingHour);

                    if (isValidPickUpTime) {
                        return true;
                    } else {
                        return createError({
                            path,
                            message: translate("isClosedText")
                        });
                    }
                }
            }

            const pickupHasPassed = hasPickupTimeSessionExpired({ date: parent.pickupDate, time: value }, backendDiff);
            if (pickupHasPassed) {
                return createError({
                    path,
                    message: translate("pickupTimePassed")
                });
            }
            return true;
        }
        return false;
    }

    function validatePickupDate(this: Yup.TestContext, value?: string) {
        const { createError, parent } = this;
        if (!!value) {
            const selectedDateWeekday = toDayName(value);
            let activeHour = getShopsActiveDay(activeHours, selectedDateWeekday.toUpperCase());
            if (!activeHour) {
                // check yesterday midnight hour
                const yesterday = moment(value, "YYYY-MM-DD").subtract(1, "day").format("dddd").toUpperCase();
                const yesterdayActiveHour = getShopsActiveDay(activeHours, yesterday);
                if (yesterdayActiveHour) {
                    const [stopHour] = getHoursAndMinutes(yesterdayActiveHour.stoppingHour);
                    if (stopHour < MIDNIGHT_HOURS) {
                        activeHour = yesterdayActiveHour;
                    }
                }
            }
            const lessThanFifteen = isLessThanFifteenMin(value, activeHour, backendDiff);

            if (lessThanFifteen) {
                return createError({
                    path: this.path,
                    message: `${translate("restaurantCloses")} ${translate("theClock")}. ${
                        activeHour!.stoppingHour
                    } ${translate("wontTakeMoreOrders")}.`
                });
            }

            if (parent.eatingPreference === EatingPreference.CATERING) {
                const customCateringDates = parent.tmpDeliveryInformation?.customCateringDates ?? [];
                const { customSpecificCateringDates, lastOrderDay } = cateringSettings;
                const excludedDays = cateringExcludedDates(
                    customCateringDates,
                    customSpecificCateringDates,
                    lastOrderDay,
                    parent.pickupStartDate
                );

                const pickupDateMoment = moment(value);
                const isDayExcluded = excludedDays.some(excludedDay => excludedDay.isSame(pickupDateMoment, "day"));
                if (isDayExcluded) {
                    return createError({
                        path: this.path,
                        message: translate("chooseAnotherDay")
                    });
                }
            }

            return true;
        }
        return false;
    }

    function validateWillDeliverCatering(this: Yup.TestContext, value?: string) {
        const { createError, path, parent } = this;

        const hasDelivery = hasCateringDeliveryAreas(cateringSettings);

        if (parent.eatingPreference === "CATERING" && hasDelivery) {
            if (!value) {
                return createError({ path, message: translate("requiredError") });
            }
            return true;
        }
        return true;
    }

    function validateCustomerType(this: Yup.TestContext, value?: string) {
        const { createError, path, parent } = this;

        const acceptInvoicePayment = shop?.settings?.cateringSettings?.acceptInvoicePayment ?? false;

        if (parent.eatingPreference === "CATERING" && acceptInvoicePayment) {
            if (!value) {
                return createError({ path, message: translate("requiredError") });
            }
            return true;
        }

        return true;
    }

    function validateTmpPrevPostCode(this: Yup.TestContext, value?: string) {
        const { createError, path, parent } = this;

        const willDeliverCatering = parent.willDeliverCatering === "YES";
        const isCatering = parent.eatingPreference === EatingPreference.CATERING;
        const isHomeDelivery = parent.eatingPreference === EatingPreference.HOME_DELIVERY;

        if (isHomeDelivery || (willDeliverCatering && isCatering)) {
            if (!value) {
                return createError({ path, message: translate("requiredError") });
            }

            if (value !== parent.postCode) {
                return createError({ path, message: "Please validate" });
            }
        }

        return true;
    }

    function validateTmpDeliveryInformation(this: Yup.TestContext, value?: string) {
        const { createError, path, parent } = this;

        const willDeliverCatering = parent.willDeliverCatering === "YES";
        const isCatering = parent.eatingPreference === EatingPreference.CATERING;
        const isHomeDelivery = parent.eatingPreference === EatingPreference.HOME_DELIVERY;

        if ((isHomeDelivery || (willDeliverCatering && isCatering)) && !value) {
            return createError({ path, message: translate("requiredError") });
        }

        return true;
    }

    const validationSchema = Yup.object().shape({
        eatingPreference: Yup.string().required(translate("requiredError")),
        tmpPrevPostCode: Yup.string().test("validateTempPostCode", translate("requiredError"), validateTmpPrevPostCode),
        willDeliverCatering: Yup.string().test(
            "validateWillDeliverCatering",
            translate("requiredError"),
            validateWillDeliverCatering
        ),
        customerType: Yup.string().test("validateCustomerType", translate("requiredError"), validateCustomerType),
        pickupTime: Yup.string().test("validatePickupTime", translate("requiredError"), validatePickupTime),
        pickupDate: Yup.string().test("validatePickupDate", translate("requiredError"), validatePickupDate),
        tmpDeliveryInformation: Yup.object()
            .nullable()
            .test("validateTmpDeliveryInformation", translate("requiredError"), validateTmpDeliveryInformation)
    });

    const getEatingPreferenceToSet = ({ eatingPreference, willDeliverCatering }: CustomerPickupFormik) => {
        if (eatingPreference === EatingPreference.CATERING) {
            if (willDeliverCatering === "YES") {
                return EatingPreference.CATERING_DELIVERY;
            } else {
                return EatingPreference.CATERING_TAKE_AWAY;
            }
        } else {
            return eatingPreference;
        }
    };

    const handleOnSubmit = (values: CustomerPickupFormik) => {
        const eatingPreference = getEatingPreferenceToSet(values);

        handleSetOnlineActiveHours(values.onlineActiveHours);

        const willDeliverCatering = values.willDeliverCatering === "YES";
        const fee = values.tmpDeliveryInformation?.deliveryFee ?? 0;

        if (values.isQuickServeActive) {
            handleSetTable(quickServeTable);
        } else if (quickServeTable?.id === table?.id) {
            // in case user changes from quickServe to other options
            // but shouldn't affect normal QR table flow
            handleSetTable(null);
        }

        handleSetFoodOptions({
            eatingPreference,
            pickupOptions: {
                time: values.pickupTime,
                date: values.pickupDate,
                isEarliest: values.isEarliest
            },
            deliveryInformation: {
                postCode: values.postCode,
                fee,
                thirdPartyDelivery: values.tmpDeliveryType as ThirdPartyDeliveryType,
                timeInterval: values.timeInterval,
                willDeliverCatering,
                customerType: values.customerType as CustomerType
            },
            cateringInformation: {
                earliestHoursToConfirmCatering: getTimeToConfirm(cateringSettings.timeToConfirm)
            }
        });

        closeModal();
    };

    const { dictionary, userLanguage } = useLanguage();

    return (
        <Modal open={open} size="md" maxW="50rem" rounded="md" isScrolling onClose={closeModal}>
            <ModalHeader pb={3}>
                <Box width="84%" bg="white" position="absolute" textAlign="center" zIndex={1800}>
                    {headerInfo && headerInfo.titleKey === "rushHourTitle" && dictionary[headerInfo.titleKey]}
                    {!!onlineSettings.rushHourWaitMinutes &&
                        " - " + dictionary["approx"] + " " + onlineSettings.rushHourWaitMinutes + " min"}
                </Box>
            </ModalHeader>
            <ModalCloseBtn onClick={closeModal} zIndex={1500} />
            <ModalBody>
                <Formik<CustomerPickupFormik>
                    validationSchema={validationSchema}
                    initialValues={getInitialValues(
                        foodOptions,
                        eatingOptionsToUse,
                        cateringSettings,
                        activeHours,
                        userLanguage,
                        backendDiff,
                        menus,
                        isQuickServeActive,
                        shopCity
                    )}
                    onSubmit={handleOnSubmit}
                    validateOnBlur
                    validateOnChange
                >
                    {formikProps => {
                        const { values, handleSubmit, isSubmitting, errors } = formikProps;

                        const Component = formContents[values.eatingPreference];

                        const selectedDateWeekday = toDayName(values.pickupDate);
                        const onlineActiveHour = getShopsActiveDay(
                            values.onlineActiveHours,
                            selectedDateWeekday.toUpperCase()
                        );

                        const _isLessThanFifteenMin = onlineActiveHour
                            ? isLessThanFifteenMin(values.pickupDate, onlineActiveHour, backendDiff)
                            : false;

                        const hasOnlyCatering = eatingOptionsToUse.every(
                            eatingOption => eatingOption === EatingPreference.CATERING
                        );

                        const ownProps = {
                            onlineActiveHour,
                            isLessThanFifteenMin: _isLessThanFifteenMin,
                            hasOnlyCatering
                        };

                        const props = {
                            ...formikProps,
                            ...ownProps
                        };

                        let shouldDisableButton = isSubmitting || !!Object.keys(errors).length;
                        let buttonText = translate("continueToOrder");
                        if (!eatingOptionsToUse.includes(EatingPreference.CATERING) && _isLessThanFifteenMin) {
                            shouldDisableButton = true;
                        }

                        const shouldShowEatingOptions = !hasOnlyCatering;

                        return (
                            <form onSubmit={handleSubmit}>
                                {shouldShowEatingOptions && (
                                    <Box role="radiogroup" mb={6}>
                                        <PickEatingPreference shopsActiveHours={activeHours} {...props} />
                                    </Box>
                                )}
                                <Collapsable open={!!values.eatingPreference}>
                                    <Component {...props} />
                                </Collapsable>
                                <Flex justify="flex-end" mt={6} position="sticky" bottom={0} zIndex={2} bg="white">
                                    <NewButton
                                        type="submit"
                                        backgroundColor="newPrimary"
                                        themeColor="green"
                                        color="newPrimaryFont"
                                        fullWidth
                                        size="lg"
                                        isLoading={isSubmitting}
                                        isDisabled={shouldDisableButton}
                                    >
                                        {buttonText}
                                    </NewButton>
                                </Flex>
                            </form>
                        );
                    }}
                </Formik>
            </ModalBody>
        </Modal>
    );
};
