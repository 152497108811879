import moment, { Moment } from "moment";

import { CateringSettings, ActiveHour } from "Types";
import { ILanguages, EatingPreference } from "Providers";
import { getShopsActiveDay, getHoursAndMinutes, nearestFutureMinutes } from "Utils";
import { getPickupStartDate, getPickupTimeStart } from "./customerPickupHelpers";
import { NO_CATERING_PICKUP_HOURS_DEFAULT_TIME } from "../components";

const backendDayToMomentDayNo = {
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
    SUNDAY: 0
};

const getMappedCateringActiveHours = (cateringMenuActiveHours: ActiveHour[], earliestTimeToOrder: moment.Moment) => {
    return cateringMenuActiveHours
        .map(active => {
            //@ts-ignore
            const dayNo = backendDayToMomentDayNo[active.dayOfWeek];
            let momentTimestamp;

            const earliestDayNo = earliestTimeToOrder.day();

            if (earliestDayNo <= dayNo) {
                momentTimestamp = moment().locale("sv").day(dayNo);
            } else {
                momentTimestamp = moment().locale("sv").add(1, "weeks").day(dayNo);
            }

            return {
                ...active,
                momentTimestamp,
                formattedM: momentTimestamp.format("YYYY-MM-DD HH:mm")
            };
        })
        .sort((a, b) => (a.momentTimestamp.isBefore(b.momentTimestamp) ? -1 : 1));
};

export const adjustPickupStartDateToOnlineActiveHours = (
    pickupStartDate: Moment,
    pickupStartDateCateringActiveHours: ActiveHour,
    onlineActiveHours: ActiveHour[]
) => {
    const [endHour, endMinute] = getHoursAndMinutes(pickupStartDateCateringActiveHours.stoppingHour);
    const pickupStartDateClosingDate = moment(pickupStartDate).set({ hour: endHour, minute: endMinute, second: 0 });

    // Too late to order today
    if (pickupStartDate.isAfter(pickupStartDateClosingDate)) {
        const cateringActiveHours = getMappedCateringActiveHours(onlineActiveHours, pickupStartDate);

        const foundNextAvailableDay = cateringActiveHours.find(activeHour => {
            if (activeHour.momentTimestamp.isAfter(pickupStartDate)) {
                return activeHour;
            }
        });

        if (foundNextAvailableDay) {
            return foundNextAvailableDay.momentTimestamp;
        }
    }
    return pickupStartDate;
};

export const getCateringPickupOptions = (
    cateringSettings: CateringSettings,
    onlineActiveHours: ActiveHour[],
    userLanguage: ILanguages,
    backendDiff: number,
    pickupOptions: { time: string | undefined; date: string | undefined; isEarliest: boolean } | null
) => {
    let pickupStartDate = getPickupStartDate(EatingPreference.CATERING, cateringSettings, userLanguage, backendDiff);
    const pickupStartDateDayName = moment(pickupStartDate).format("dddd").toUpperCase();
    const pickupStartDateCateringActiveHours = getShopsActiveDay(onlineActiveHours, pickupStartDateDayName);

    if (pickupStartDateCateringActiveHours) {
        pickupStartDate = adjustPickupStartDateToOnlineActiveHours(
            pickupStartDate,
            pickupStartDateCateringActiveHours,
            onlineActiveHours
        );
    }

    let formattedPickupDate = pickupStartDate.format("YYYY-MM-DD");

    if (!!cateringSettings?.customCateringDates?.length) {
        const allAllowedDays = [...new Set(cateringSettings.customCateringDates.flatMap(obj => obj.days))];
        if (!allAllowedDays.includes(pickupStartDate.format("dddd").toUpperCase())) {
            formattedPickupDate = "";
        }
    }

    let pickupTime = "";
    if (!!formattedPickupDate) {
        if (cateringSettings.noPickupHours) {
            pickupTime = NO_CATERING_PICKUP_HOURS_DEFAULT_TIME;
        } else {
            const activeHourFromNoticeHours = getShopsActiveDay(
                onlineActiveHours,
                pickupStartDate.locale("en").format("dddd").toUpperCase()
            );

            if (activeHourFromNoticeHours) {
                const pickupTimeStart = getPickupTimeStart(
                    true,
                    formattedPickupDate,
                    activeHourFromNoticeHours!,
                    cateringSettings.noticeHours,
                    backendDiff
                );
                const [hour, min] = getHoursAndMinutes(pickupTimeStart);
                pickupTime = nearestFutureMinutes(15, moment().hour(hour).minutes(min)).format("HH:mm");
            }
        }
    }

    return {
        pickupTime: pickupOptions?.time ?? pickupTime,
        pickupDate: pickupOptions?.date ?? formattedPickupDate,
        pickupStartDate: pickupStartDate
    };
};
