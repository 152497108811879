import React from "react";

import { FormRadios } from "Molecules";
import { useLanguage } from "Providers";
import { CustomerPickupFormComponent } from "../..";

export const CateringContentWillDeliverCateringField: React.FC<CustomerPickupFormComponent> = ({ values }) => {
    const { translate } = useLanguage();

    const options = [
        { showWhen: true, key: translate("homeDelivery"), value: "YES" },
        { showWhen: values.tmpDeliveryInformation?.allowPickup, key: translate("pickup2"), value: "NO" }
    ]
        .filter(option => option.showWhen)
        .map(({ key, value }) => ({ key, value }));

    return (
        <FormRadios
            name="willDeliverCatering"
            options={options}
            formLabel={translate("deliveryType")}
            wrapperProps={{ mb: 0 }}
        />
    );
};
