import moment from "moment";

import { getShopsActiveDay } from "Utils";
import { ActiveHour } from "Types";

export const getActiveHoursText = (
    isInactive: boolean,
    activeHours: ActiveHour[],
    pickupDate: string,
    inactiveText: string,
    getTodaysActiveHour: (activeHours?: ActiveHour[]) => ActiveHour | undefined
) => {
    let activeHoursText = inactiveText;

    const todayActiveHours = getTodaysActiveHour(activeHours);

    if (todayActiveHours) {
        if (isInactive) {
            activeHoursText = `${todayActiveHours?.startingHour} - ${todayActiveHours?.stoppingHour} (${inactiveText})`;
        } else {
            activeHoursText = `${todayActiveHours?.startingHour} - ${todayActiveHours?.stoppingHour}`;
        }
    } else if (!isInactive) {
        const pickupDayWorkingHours = getShopsActiveDay(activeHours, moment(pickupDate).format("dddd").toUpperCase());

        if (pickupDayWorkingHours) {
            activeHoursText = `${pickupDayWorkingHours?.startingHour} - ${pickupDayWorkingHours?.stoppingHour}`;
        }
    }
    return activeHoursText;
};
