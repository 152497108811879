import React from "react";
import { FaRegCalendarAlt } from "@react-icons/all-files/fa/FaRegCalendarAlt";
import { FaClock } from "@react-icons/all-files/fa/FaClock";
import { SiAirtable } from "@react-icons/all-files/si/SiAirtable";

import { Flex, Text, Box } from "Atoms";
import { NewIconButton } from "Molecules";
import { onlineEatingPreferencesIcons } from "OnlineUtils";
import { OnlineHeartIconButton } from "OnlineComponents";
import { LanguageHeader, LanguageText } from "../../../../../../common/components";
import { useOnlineStore } from "Stores";

type Props = {
    renderEmptyCart: any;
    pickupText: any;
    openPickupModal: () => void;
};

export const OnlineOrderStickyCartHeader: React.FC<Props> = ({ renderEmptyCart, pickupText, openPickupModal }) => {
    const {
        table,
        foodOptions: { eatingPreference }
    } = useOnlineStore();

    const headerKey = renderEmptyCart ? "emptyCart" : "yourOrder";
    const { icon, text } = onlineEatingPreferencesIcons[eatingPreference];

    return (
        <Flex justify="space-between" width="100%" direction="column" p={6}>
            <Flex justify="space-between" align="center">
                <LanguageHeader tid={headerKey} as="h3" size="lg" color="gray.700" />
                <Flex>
                    <OnlineHeartIconButton isDesktop />
                    <NewIconButton icon={FaRegCalendarAlt} fontSize="20px" size="lg" onClick={openPickupModal} />
                </Flex>
            </Flex>
            <Flex align="center">
                {table && table.name && (
                    <Flex color="gray.700" mr={4} align="center">
                        <Box as={SiAirtable} size="20px" color="gray.700" mr={1} />
                        <Text as="span" color="inherit">
                            {table.name}
                        </Text>
                    </Flex>
                )}
                <Flex align="center" mr={4} color="gray.700">
                    {icon && <Box as={icon} color="inherit" size="16px" mr={1} />}
                    <LanguageText tid={text} as="span" color="inherit" />
                </Flex>
                <Flex align="center" color="gray.700">
                    <Box as={FaClock} color="inherit" size="16px" mr={1} />
                    <Text as="span" color="inherit">
                        {pickupText}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    );
};
