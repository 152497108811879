import React from "react";

import { Box, PseudoBox } from "Atoms";

const secondRibbon: any = () => {
    return {
        width: "150px",
        height: "150px",
        overflow: "hidden",
        position: "absolute",
        top: "-10px",
        right: "-10px"
    };
};

const textSpan: any = () => {
    return {
        position: "absolute",
        display: "block",
        width: "225px",
        left: "-25px",
        top: "30px",
        backgroundColor: "#BEE3F8",
        textTransform: "uppercase",
        transform: "rotate(45deg)",
        fontSize: "0.9rem",
        margin: "1rem",
        textAlign: "center",
        fontWeight: "bold",
        letterSpacing: "1px"
    };
};

export const OnlineOrderMiniCardRibbon: React.FC<{ value: string }> = ({ value }) => {
    return (
        <PseudoBox {...secondRibbon()}>
            <Box as="span" {...textSpan()} color="gray.800">
                {value}
            </Box>
        </PseudoBox>
    );
};
