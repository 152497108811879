import React from "react";
import moment from "moment";

import { useOnline, EatingPreference, useLanguage, isEatingPreferenceCatering, usePos } from "Providers";
import { Dimmer, DimmerContent } from "Molecules";
import { Flex, Text, Button, NewDivider, FormHelperText, Box } from "Atoms";
import {
    getCartTotalPrice,
    getActiveHourByWeekday,
    isCartFinished,
    formatSwedishFinancialNumbers,
    calculateDeliveryFee
} from "Utils";
import { LanguageText, LanguageHeader } from "Components";
import { OnlineOrderStickyCartHeader } from "./components";
import { OnlineCustomerCartList, OnlineDiscountSection } from "OnlineComponents";
import { formatPickupDate } from "../../../../utils/formatPickupDate";
import { getOnlineBrowseOnlyInfo, getMinOrderTotalAmount, shouldDisableOnlineOrderCart } from "OnlineUtils";
import { CartProduct, Settings, ActiveHour } from "Types";
import { useShouldClearOnlineCart } from "../../hooks";
import { useOrderWindowsStore, useQoplaStore } from "Stores";

type Props = {
    upsellCategory: any;
    outOfStockIds: any;
    productsIdsAndQuantity: any;
    openPickupModal: () => void;
    isProductCommentsEnabled?: boolean;
    onNavigateToCheckout: () => void;
};

export const OnlineOrderStickyCart: React.FC<Props> = props => {
    const { openPickupModal, isProductCommentsEnabled, onNavigateToCheckout } = props;
    const { getActiveOrderWindow } = useOrderWindowsStore();
    const orderWindow = getActiveOrderWindow();

    useShouldClearOnlineCart();

    const { selectedShop: shop } = useQoplaStore();
    const { menus } = usePos();
    const {
        foodOptions: { pickupOptions, eatingPreference, deliveryInformation },
        browseMode
    } = useOnline();
    const { translate, userLanguage } = useLanguage();

    const settings = shop?.settings as Settings;
    const activeHours = shop?.activeHours as ActiveHour[];
    const {
        homeDeliverySettings: { minAmountFreeDelivery },
        cateringSettings: { noPickupHours }
    } = settings;

    const _isCatering = isEatingPreferenceCatering(eatingPreference);
    const isHomeDelivery = eatingPreference === EatingPreference.HOME_DELIVERY;
    const isCateringDelivery = eatingPreference === EatingPreference.CATERING_DELIVERY;
    const isHomeDeliveryOrCatering = isHomeDelivery || _isCatering;
    const isDeliveryAndHasDeliveryInfo = (isHomeDelivery || isCateringDelivery) && !!deliveryInformation;
    const minOrderTotalAmount = getMinOrderTotalAmount(eatingPreference, settings);

    const subTotal = getCartTotalPrice(orderWindow?.cartProducts);
    const addedDeliveryFee = isDeliveryAndHasDeliveryInfo
        ? calculateDeliveryFee(subTotal, minAmountFreeDelivery, deliveryInformation.fee)
        : 0;
    const totalPrice = subTotal + addedDeliveryFee;
    const hasMetMinHomeDeliveryAmount = isHomeDeliveryOrCatering && subTotal < minOrderTotalAmount;

    const renderEmptyCart = !orderWindow?.cartProducts.length;
    const toCheckoutDisabled =
        !isCartFinished(orderWindow?.cartProducts) ||
        hasMetMinHomeDeliveryAmount ||
        (!_isCatering && browseMode.hasNoActiveMenus);

    const activeHour = getActiveHourByWeekday(activeHours, moment(pickupOptions.date).format("dddd").toUpperCase());
    const pickupText = formatPickupDate(
        pickupOptions,
        userLanguage,
        eatingPreference,
        noPickupHours,
        activeHour,
        deliveryInformation?.timeInterval
    );
    const headerInfo = getOnlineBrowseOnlyInfo(browseMode);
    const leftTextKey = _isCatering ? "restForCatering" : "restForHomeDelivery";
    const hasAppliedDiscount: boolean = orderWindow?.discount && orderWindow?.cartProducts.length > 0;
    const hasFixedDiscountProducts = orderWindow?.cartProducts?.some(
        (cart: CartProduct) => cart.fixedDiscount?.isFixedDiscountActive
    );

    const shouldDim = shouldDisableOnlineOrderCart(menus, browseMode);

    return (
        <Dimmer isActive={shouldDim}>
            <Flex direction="column" boxShadow="xl" maxHeight="xs" rounded="lg" backgroundColor="white">
                <Flex h="auto">
                    <OnlineOrderStickyCartHeader
                        renderEmptyCart={renderEmptyCart}
                        openPickupModal={openPickupModal}
                        pickupText={pickupText}
                    />
                </Flex>
                {!!orderWindow?.cartProducts.length && (
                    <Flex px={4} pt={4} pb={8} flex="1" overflow="auto">
                        <OnlineCustomerCartList
                            appliedDiscount={orderWindow.discount}
                            isProductCommentsEnabled={isProductCommentsEnabled}
                        />
                    </Flex>
                )}
                <NewDivider color="gray.400" />
                <Flex direction="column" h="auto" padding={4}>
                    {(isCateringDelivery || isHomeDelivery) && !renderEmptyCart && (
                        <Box mb={4}>
                            <Flex justify="space-between" h="auto" align="center">
                                <LanguageText tid="deliveryFee" fontSize="lg" color="gray.700" m="0" />
                                <Text fontSize="xl" color="gray.700" m="0">
                                    {addedDeliveryFee === 0 ? translate("free") : `${addedDeliveryFee} kr`}
                                </Text>
                            </Flex>
                            {subTotal <= minAmountFreeDelivery && (
                                <FormHelperText as="span">
                                    {translate("freeDeliveryOver")} {minAmountFreeDelivery} kr
                                </FormHelperText>
                            )}
                        </Box>
                    )}
                    {(hasAppliedDiscount || hasFixedDiscountProducts) && !!orderWindow && <OnlineDiscountSection />}
                    <Flex justify="space-between" h="auto">
                        <LanguageText tid="total" as="span" fontSize="2xl" fontWeight="bold" color="gray.900" />
                        <Text as="span" fontSize="2xl" fontWeight="bold" color="gray.900">
                            {formatSwedishFinancialNumbers(totalPrice)}
                        </Text>
                    </Flex>
                    {!renderEmptyCart && (
                        <Button
                            mt={6}
                            minH={12}
                            backgroundColor="newPrimary"
                            themeColor="green"
                            color="newPrimaryFont"
                            size="lg"
                            fullWidth
                            onClick={onNavigateToCheckout}
                            disabled={toCheckoutDisabled}
                        >
                            {hasMetMinHomeDeliveryAmount
                                ? `${translate(leftTextKey)} ${formatSwedishFinancialNumbers(
                                      minOrderTotalAmount - subTotal
                                  )}`
                                : translate("toCheckout")}
                        </Button>
                    )}
                </Flex>
            </Flex>
            <DimmerContent rounded="lg" bg="blackAlpha.800">
                {shouldDim && headerInfo && (
                    <LanguageHeader tid={headerInfo.titleKey} as="h3" size="lg" color="white" />
                )}
            </DimmerContent>
        </Dimmer>
    );
};
