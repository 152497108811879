import React from "react";

import { ButtonProps, NewButton, Box, Text, Flex } from "Atoms";
import { LanguageText } from "Components";
import { Option } from "./PickEatingPreference";

export interface IPickupOption {
    isActive: boolean;
    isDisabled?: boolean;
    show?: boolean;
    titleKey: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    icon: React.FunctionComponent;
    openAt: string;
}

type Props = Option & ButtonProps;

export const CustomerPickupOptionBtn: React.FC<Props> = ({
    titleKey,
    onClick,
    icon,
    isActive,
    show = true,
    openAt,
    isQuickServe,
    ...rest
}) => {
    if (show) {
        return (
            <Flex direction="column">
                <NewButton
                    size="lg"
                    onClick={onClick}
                    type="button"
                    themeColor={isActive ? "blue" : "gray"}
                    borderWidth="3px"
                    {...rest}
                >
                    <Box size="20px" color={isActive ? "white" : "gray.700"} mr={2} as={icon} />
                    {
                        // for quick Serve we will use table name, which we shouldn't translate
                        isQuickServe ? <Text mb={0}>{titleKey}</Text> : <LanguageText tid={titleKey} fontSize="lg" />
                    }
                </NewButton>
                <Text as="span" textAlign="center" fontSize="sm" mt={1}>
                    {openAt}
                </Text>
            </Flex>
        );
    } else return null;
};
