import { ONLINE_SUBSCRIPTION_CATEGORY_ID } from "Constants";
import { TranslateFunc } from "Providers";
import {
    CompanySubscriptionSettings,
    MenuBundleProduct,
    MenuProduct,
    MenuProductCategory,
    Subscriptions,
    UserSubscriptionDTO
} from "Types";
import { sortSubscriptions } from "Utils";

const transformToShopPromotionCategory = (
    subscriptions: Subscriptions,
    companySubscriptionSettings: CompanySubscriptionSettings | null,
    translate: TranslateFunc
): MenuProductCategory[] => {
    subscriptions.sort(sortSubscriptions);

    const subscriptionsAsProducts = subscriptions.map(subscription => {
        return {
            id: subscription.id,
            refProduct: {
                id: subscription.id,
                name: subscription.name,
                description: subscription.description,
                imageUrl: subscription.imageUrl
            },
            price: subscription.pricePlans[0].amount,
            sortOrder: 0,
            modifications: null,
            activeHours: [],
            productQuantity: 0,
            isOutOfStock: false
        };
    });

    return [
        {
            id: ONLINE_SUBSCRIPTION_CATEGORY_ID,
            name: translate("subscriptions"),
            sortOrder: 0,
            upsellCategory: false,
            upsellCategoryAtPayment: false,
            description: companySubscriptionSettings?.description,
            imageUrl: companySubscriptionSettings?.imageUrl,
            menuProducts: subscriptionsAsProducts as any,
            menuBundleProducts: [],
            isSubscriptionCategory: true,
            posFavorite: false
        }
    ];
};

const transformSubscriptionProductsToMenuProducts = (
    userSubscription: UserSubscriptionDTO,
    allProducts: (MenuProduct | MenuBundleProduct)[]
) => {
    return userSubscription.subscription.subscriptionProducts.reduce<{
        menuProducts: MenuProduct[];
        menuBundleProducts: MenuBundleProduct[];
    }>(
        (acc, { __typename, ...next }) => {
            const found = allProducts.find(product => {
                const prod = "refProduct" in product ? product.refProduct : product.refBundleProduct;
                return next.refProductId === prod.id;
            });

            const subscriptionProductMeta = {
                ...next,
                subscriptionId: userSubscription.subscription.id
            };

            if (found) {
                const isBundle = "refBundleProduct" in found;
                if (isBundle) {
                    //@ts-ignore
                    acc.menuBundleProducts.push({ ...found, subscriptionProductMeta });
                } else {
                    //@ts-ignore
                    acc.menuProducts.push({ ...found, subscriptionProductMeta });
                }
            }
            return acc;
        },
        { menuProducts: [], menuBundleProducts: [] }
    );
};

const transformSubscriptionsToMenuProductCategories = (
    userSubscriptions: UserSubscriptionDTO[],
    allProducts: (MenuProduct | MenuBundleProduct)[],
    translate: TranslateFunc
): MenuProductCategory[] => {
    return userSubscriptions.map(userSubscription => {
        const { menuProducts, menuBundleProducts } = transformSubscriptionProductsToMenuProducts(
            userSubscription,
            allProducts
        );
        // it seems like this part always will return true
        /*const hasUserEnrolledSubscription = userSubscriptions.some(
            userSubscription => userSubscription.subscription.id === userSubscription.subscription.id
        );*/

        const name = `${translate("subscriptions")} ${userSubscription.subscription.name}`;

        return {
            id: userSubscription.subscription.id,
            name,
            sortOrder: 0,
            upsellCategory: false,
            upsellCategoryAtPayment: false,
            description: "",
            imageUrl: userSubscription.subscription.imageUrl,
            menuProducts,
            menuBundleProducts,
            isSubscriptionCategory: true,
            posFavorite: false
        };
    });
};

export const getSubscriptionMenuProductCategories = (
    subscriptions: Subscriptions,
    userSubscriptions: UserSubscriptionDTO[],
    allProducts: (MenuProduct | MenuBundleProduct)[],
    showAsEnrolledSubscriptions: boolean,
    companySubscriptionSettings: CompanySubscriptionSettings | null,
    translate: TranslateFunc
): MenuProductCategory[] => {
    if (showAsEnrolledSubscriptions) {
        return transformSubscriptionsToMenuProductCategories(userSubscriptions, allProducts, translate);
    } else {
        return transformToShopPromotionCategory(subscriptions, companySubscriptionSettings, translate);
    }
};
