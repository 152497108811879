import React from "react";
import { Field } from "formik-next";

import { CAFE_HALLONBERGEN_COMPANY_ID, RÅ_OCH_SÅ_AB_COMPANY_ID } from "Constants";
import { SelectInput } from "Molecules";
import { useLanguage } from "Providers";
import { useQoplaStore } from "Stores";
import { CustomerPickupFormComponent } from "../..";

export const CateringContentTimeIntervalField: React.FC<CustomerPickupFormComponent> = props => {
    const { translate } = useLanguage();
    const { selectedShop } = useQoplaStore();

    const isCafeHallonBergen = CAFE_HALLONBERGEN_COMPANY_ID === selectedShop?.companyId;
    const isRaOchSa = RÅ_OCH_SÅ_AB_COMPANY_ID === selectedShop?.companyId;
    const willDeliverCatering = props.values.willDeliverCatering === "YES";

    if ((!isCafeHallonBergen && !isRaOchSa) || !willDeliverCatering) {
        return null;
    }

    const options = isCafeHallonBergen
        ? [
              { value: "08:00-13:00", label: "08:00-13:00" },
              { value: "13:00-18:00", label: "13:00-18:00" },
              { value: "18:00-22:00", label: "18:00-22:00" }
          ]
        : [{ value: "18:00-22:00", label: "18:00-22:00" }];

    return (
        <Field
            as={SelectInput}
            formLabel={translate("timeInterval")}
            placeholder={translate("timeInterval")}
            name="timeInterval"
            setFieldValue={props.setFieldValue}
            onBlur={props.setFieldTouched}
            options={options}
            isMandatory
            validate={(value: string) => {
                if (!value) return translate("requiredError");
            }}
        />
    );
};
