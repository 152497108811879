import React from "react";
import { FormikErrors, FormikProps } from "formik-next";

import { useLanguage } from "Providers";
import { Select } from "Molecules";
import { FormErrorMessage, Box, FormLabel, FormHelperText } from "Atoms";
import { CustomerPickupFormik } from "../..";

//@ts-ignore
const DatePicker = ({ options, isDisabled, value, onChange, formLabelKey, placeholderKey, helperText, error }: any) => {
    const { translate } = useLanguage();
    return (
        <Box>
            <FormLabel>{translate(formLabelKey)}</FormLabel>
            <Select
                isDisabled={isDisabled}
                value={value}
                onChange={({ target }: any) => onChange(target)}
                placeholder={translate(placeholderKey)}
                fullWidth
                size="lg"
            >
                {options.map(({ text, dataObject, ...option }: any) => (
                    <option key={option.value} data-container={dataObject} {...option}>
                        {text}
                    </option>
                ))}
            </Select>
            {!!error && <FormErrorMessage>{error}</FormErrorMessage>}
            {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Box>
    );
};

type Props = {
    isLessThanFifteenMin: boolean;
    stops: any;
    type: "DATE" | "TIME";
    onChange: (value: string) => void;
    helperText?: string;
} & FormikProps<CustomerPickupFormik>;

export const DateOrTimePicker: React.FC<Props> = ({
    isLessThanFifteenMin,
    stops,
    type,
    onChange,
    helperText,
    errors,
    ...formProps
}) => {
    const { values } = formProps;

    const types = {
        DATE: {
            placeholderKey: "selectADay",
            formLabelKey: "date",
            name: "pickupDate"
        },
        TIME: {
            placeholderKey: "selectATime",
            formLabelKey: "time",
            name: "pickupTime"
        }
    };

    const texts = types[type];

    const error = errors[texts.name as keyof FormikErrors<CustomerPickupFormik>];

    const notAvailableForQuickServe = values.isQuickServeActive && type === "DATE";

    return (
        <DatePicker
            options={stops}
            isDisabled={isLessThanFifteenMin || notAvailableForQuickServe}
            value={type === "DATE" ? values.pickupDate : values.pickupTime}
            onChange={onChange}
            helperText={helperText}
            error={error}
            {...texts}
        />
    );
};
