import React from "react";

import { Box, Flex, FormHelperText } from "Atoms";
import { LanguageHeader } from "Components";
import { CustomerPickupOptionLbl, CustomerPickupFormComponent, PickupOptions, PostCodeField } from ".";
import { useQoplaStore } from "Stores";
import { useLanguage } from "Providers";
import { getPostCodeHelperText } from "../utils";

export const HomeDeliveryContent: React.FC<CustomerPickupFormComponent> = props => {
    const { values } = props;

    const { selectedShop, companyLocale } = useQoplaStore();
    const { translate } = useLanguage();

    const deliveryFee = values.tmpDeliveryInformation?.deliveryFee ?? 0;
    const minAmountFreeDelivery = selectedShop?.settings?.homeDeliverySettings?.minAmountFreeDelivery ?? 0;

    const helperText = getPostCodeHelperText(deliveryFee, minAmountFreeDelivery, companyLocale, translate);

    return (
        <Box>
            <Flex align="center" mb={4}>
                <CustomerPickupOptionLbl isCompleted={false} text="2" />
                <LanguageHeader tid="dateAndTime" as="h3" size="md" color="gray.800" m="0" />
            </Flex>
            <PickupOptions {...props} />
            <Box>
                <PostCodeField {...props} />
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </Box>
        </Box>
    );
};
