import React from "react";

import { Box, Collapsable, FormHelperText } from "Atoms";
import {
    CateringContentCustomerTypeField,
    CateringContentInfoText,
    CateringContentTimeIntervalField,
    CateringContentWillDeliverCateringField
} from "./";
import { useQoplaStore } from "Stores";
import { useLanguage } from "Providers";
import { PostCodeField, PickupOptions, CustomerPickupFormComponent } from "../../";
import { getPostCodeHelperText } from "../../../utils/getPostCodeFieldHelperText";

export const CateringContentWithCustomDatesContent: React.FC<CustomerPickupFormComponent> = props => {
    const { values, errors } = props;

    const { selectedShop, companyLocale } = useQoplaStore();
    const { translate } = useLanguage();

    const hasDeliveryInformation = !!values.tmpDeliveryInformation;
    const hasFiveDigits = values.postCode.length === 5;
    const hasNoErrors = !errors.postCode;
    const shouldShow =
        hasDeliveryInformation && hasFiveDigits && values.tmpPrevPostCode === values.postCode && hasNoErrors;

    const deliveryFee = values.tmpDeliveryInformation?.deliveryFee ?? 0;
    const minAmountFreeDelivery = selectedShop?.settings?.homeDeliverySettings?.minAmountFreeDelivery ?? 0;
    const helperText = getPostCodeHelperText(deliveryFee, minAmountFreeDelivery, companyLocale, translate);

    return (
        <Box>
            <PostCodeField {...props} />
            <Collapsable isOpen={shouldShow}>
                <Box mt={4}>
                    <CateringContentCustomerTypeField />
                    <Box mb={6}>
                        <CateringContentWillDeliverCateringField {...props} />
                        {values.willDeliverCatering === "NO" && (
                            <FormHelperText>
                                {translate("pickupAddress")}: {values.tmpDeliveryInformation?.pickupAddress}
                            </FormHelperText>
                        )}
                        {values.willDeliverCatering === "YES" && <FormHelperText>{helperText}</FormHelperText>}
                    </Box>
                    <CateringContentTimeIntervalField {...props} />
                    <PickupOptions showDisabledDates={!props.hasOnlyCatering} {...props} />
                    <CateringContentInfoText />
                </Box>
            </Collapsable>
        </Box>
    );
};
