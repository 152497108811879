import { formatFinancialNumbers } from "PriceUtils";
import { TranslateFunc } from "Providers";
import { CompanyLocale } from "Types";

export const getPostCodeHelperText = (
    deliveryFee: number,
    minAmountFreeDelivery: number,
    companyLocale: CompanyLocale,
    translate: TranslateFunc
) => {
    if (!deliveryFee) {
        return null;
    }

    let text = `${translate("deliveryFee")}: ${formatFinancialNumbers(deliveryFee, companyLocale)} ${translate(
        "includingVat"
    )}.`;

    if (minAmountFreeDelivery > 0) {
        const freeDeliveryText = ` ${translate("freeDeliveryOver")}${formatFinancialNumbers(
            minAmountFreeDelivery,
            companyLocale
        )}`;
        text += freeDeliveryText;
    }

    return text;
};
