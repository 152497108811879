import React from "react";
import { FaGift } from "@react-icons/all-files/fa/FaGift";
import { FaShippingFast } from "@react-icons/all-files/fa/FaShippingFast";
import { FaShoppingBag } from "@react-icons/all-files/fa/FaShoppingBag";
import { MdRestaurant } from "@react-icons/all-files/md/MdRestaurant";
import { FaCarAlt } from "@react-icons/all-files/fa/FaCarAlt";
import { IconType } from "@react-icons/all-files";
import { ErrorMessage } from "formik-next";
import moment from "moment";

import { ActiveHour, EatingOption, Settings, PICKUP_EATING_OPTIONS, QUICK_SERVE, EatingPreference } from "Types";
import { useLanguage, useOnline, usePos } from "Providers";
import { Flex, NewGrid, Box, FormErrorMessage } from "Atoms";
import { LanguageHeader } from "Components";
import {
    isShopOpen,
    hasCateringMenu,
    isLessThanFifteenMin,
    getMergedActiveHoursByEatingOption,
    getDisplayClosed
} from "Utils";
import { timeConstants } from "Constants";
import {
    CustomerPickupFormComponent,
    PickupFormEatingPreference,
    CustomerPickupOptionBtn,
    CustomerPickupOptionLbl
} from ".";
import { getCateringPickupOptions } from "../utils";
import { useQoplaStore, useOnlineStore } from "Stores";

export const EATING_OPTIONS: PICKUP_EATING_OPTIONS[] = [
    EatingPreference.TAKE_AWAY,
    EatingPreference.EAT_HERE,
    EatingPreference.HOME_DELIVERY,
    EatingPreference.CATERING,
    QUICK_SERVE
];

type Props = {
    shopsActiveHours: ActiveHour[];
} & CustomerPickupFormComponent;

export type Option = {
    key: PICKUP_EATING_OPTIONS;
    isActive: boolean;
    isDisabled: boolean;
    titleKey: string;
    onClick: () => void;
    icon: IconType;
    openAt: string;
    isQuickServe: boolean;
    show?: boolean;
};

const iconOptions = {
    CATERING: FaGift,
    HOME_DELIVERY: FaShippingFast,
    TAKE_AWAY: FaShoppingBag,
    EAT_HERE: MdRestaurant,
    QUICK_SERVE: FaCarAlt
};

const titleKeyOptions = {
    CATERING: "preOrderSlashCatering",
    HOME_DELIVERY: "homeDelivery",
    TAKE_AWAY: "takeAway",
    EAT_HERE: "eatHere"
};

const titleKey = (
    eatingPreference: PICKUP_EATING_OPTIONS | typeof QUICK_SERVE,
    isUnavailable: boolean,
    pauseHomeDelivery: boolean,
    quickServeTableName: string
) => {
    if (isUnavailable) {
        return "notAvailable";
    }
    if (eatingPreference === EatingPreference.HOME_DELIVERY && pauseHomeDelivery) {
        return "paused";
    }
    if (eatingPreference === QUICK_SERVE) {
        return quickServeTableName;
    }

    return titleKeyOptions[eatingPreference];
};

export const PickEatingPreference: React.FC<Props> = ({ values, setValues, shopsActiveHours }) => {
    const { userLanguage, translate } = useLanguage();
    const { selectedShop: shop } = useQoplaStore();
    const { table, backendDiff, onlineActiveHours } = useOnlineStore();
    const settings = shop?.settings as Settings;
    const { getTodaysActiveHour } = useOnline();
    const { menus } = usePos();

    const {
        onlineSettings: { eatingOptions, qrEatingOptions, qrQuickServe },
        homeDeliverySettings: { pauseHomeDelivery },
        cateringSettings
    } = settings;

    const quickServeTable =
        settings.shopTables?.find(table => table.id === qrQuickServe?.qrPickupOptionTableId) ?? null;
    //do not show quickServe button for QR tables
    const isQrTablesOrdering = !!table && quickServeTable?.id != table?.id;

    const hasPicked = (eatingPreference: EatingPreference) => values.eatingPreference === eatingPreference;
    const eatingOptionsToUse = isQrTablesOrdering ? qrEatingOptions : eatingOptions;

    const shopActiveHour = getTodaysActiveHour();
    const isLessThanFifteenMinToClosing = shopActiveHour
        ? isLessThanFifteenMin(moment().format("YYYY-MM-DD"), shopActiveHour, backendDiff)
        : false;

    const onClickEatHereOrTakeAway = (eatingPreference: PickupFormEatingPreference, isQuickServeActive = false) => {
        setValues({
            ...values,
            eatingPreference,
            pickupTime: timeConstants.ASAP,
            onlineActiveHours: shopsActiveHours,
            pickupDate: moment().add(backendDiff, "ms").locale(userLanguage).format("YYYY-MM-DD"),
            isQuickServeActive,
            isAfterMidnight: false,
            pickupStartDate: moment()
        });
    };

    const onClickOptions = {
        TAKE_AWAY: () => {
            onClickEatHereOrTakeAway(EatingPreference.TAKE_AWAY);
        },
        EAT_HERE: () => {
            onClickEatHereOrTakeAway(EatingPreference.EAT_HERE);
        },
        HOME_DELIVERY: () => {
            const homeDeliveryMenusActiveHours = getMergedActiveHoursByEatingOption(menus, EatingOption.HOME_DELIVERY);

            setValues({
                ...values,
                eatingPreference: EatingPreference.HOME_DELIVERY,
                pickupDate: moment().format("YYYY-MM-DD"),
                isEarliest: false,
                pickupTime: timeConstants.ASAP,
                onlineActiveHours: !!homeDeliveryMenusActiveHours ? homeDeliveryMenusActiveHours : shopsActiveHours,
                postCode: "",
                tmpPrevPostCode: "",
                tmpDeliveryInformation: null,
                isQuickServeActive: false,
                isAfterMidnight: false,
                pickupStartDate: moment()
            });
        },
        CATERING: () => {
            const cateringMenusActiveHours = getMergedActiveHoursByEatingOption(menus, EatingOption.CATERING);

            const cateringPickup = getCateringPickupOptions(
                cateringSettings,
                cateringMenusActiveHours,
                userLanguage,
                backendDiff,
                null
            );

            setValues({
                ...values,
                ...cateringPickup,
                eatingPreference: EatingPreference.CATERING,
                onlineActiveHours: !!cateringMenusActiveHours ? cateringMenusActiveHours : shopsActiveHours,
                postCode: "",
                tmpPrevPostCode: "",
                tmpDeliveryInformation: null,
                isQuickServeActive: false,
                isAfterMidnight: false
            });
        },
        QUICK_SERVE: () => {
            onClickEatHereOrTakeAway(EatingPreference.TAKE_AWAY, true);
        }
    };

    const _isShopOpen = isShopOpen(shopsActiveHours, backendDiff);

    const isToday = moment().add(backendDiff, "ms").format("YYYY-MM-DD") === values.pickupDate;

    const todayText = translate("today");
    const closedText = translate("closed");

    const options = EATING_OPTIONS.reduce<Option[]>((availableOptions, option) => {
        const isQuickServe = option === QUICK_SERVE;
        const shouldShow = isQuickServe
            ? !!quickServeTable && !isQrTablesOrdering
            : eatingOptionsToUse.includes(option);
        if (shouldShow) {
            let isUnavailable;

            const isCatering = option === EatingPreference.CATERING;

            if (isCatering && hasCateringMenu(menus)) {
                isUnavailable = false;
            } else {
                isUnavailable = !_isShopOpen || isLessThanFifteenMinToClosing;
            }

            const isHomeDeliveryPaused = option === EatingPreference.HOME_DELIVERY ? pauseHomeDelivery : false;

            const [startingHour, stoppingHour, displayClosed] = getDisplayClosed(
                option,
                values,
                menus,
                backendDiff,
                onlineActiveHours
            );
            const openAt = isCatering
                ? translate("alwaysOpen")
                : displayClosed
                ? closedText
                : isToday
                ? `${todayText} ${startingHour} - ${stoppingHour}`
                : `${startingHour} - ${stoppingHour}`;

            availableOptions.push({
                key: option,
                isQuickServe,
                isDisabled: isUnavailable || isHomeDeliveryPaused,
                icon: iconOptions[option],
                // Prevent clearing field values if pressing active eatingPreference
                onClick:
                    values.eatingPreference !== option || values.isQuickServeActive ? onClickOptions[option] : () => {},
                isActive: isQuickServe ? values.isQuickServeActive : hasPicked(option) && !values.isQuickServeActive,
                titleKey: titleKey(option, isUnavailable, pauseHomeDelivery, quickServeTable?.name ?? ""),
                openAt
            });
        }
        return availableOptions;
    }, []);

    return (
        <Flex direction="column">
            <Flex align="center" mb={4}>
                <CustomerPickupOptionLbl isCompleted={false} text="1" />
                <LanguageHeader tid="howToEat" as="h3" size="md" color="gray.800" m="0" />
            </Flex>
            <Box px={4}>
                <NewGrid gap={4} templateColumns={["1fr", "1fr", "1fr 1fr 1fr"]}>
                    {options.map(option => (
                        <CustomerPickupOptionBtn {...option} />
                    ))}
                </NewGrid>
                <ErrorMessage name="eatingPreference" component={FormErrorMessage} />
            </Box>
        </Flex>
    );
};
