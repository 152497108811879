import React, { useEffect } from "react";
import moment from "moment";
import { useFormikContext } from "formik-next";

import { Box, Textarea } from "Atoms";
import { DatePickerInput, FormInput } from "Molecules";
import { TranslateFunc } from "Providers";
import { GiftCardFormValues } from "../OnlineOrderGiftCard";
import { ValidateStep } from "Organisms";

export const OnlineOrderGiftCardRecipient: React.FC<{ translate: TranslateFunc }> = ({ translate }) => {
    const { validateForm, errors, values } = useFormikContext<GiftCardFormValues>();

    useEffect(() => {
        validateForm();
    }, []);

    return (
        <>
            <ValidateStep>
                {({ setValidatedStep }) => {
                    if (errors.recipient?.name || errors.recipient?.email) {
                        setValidatedStep(false);
                    } else {
                        setValidatedStep(true);
                    }

                    return (
                        <Box pr={1} pl={1}>
                            <FormInput
                                name="recipient.name"
                                fullWidth
                                isMandatory
                                formLabel={translate("recipientName")}
                                autoComplete={"off"}
                            />
                            <FormInput
                                name="recipient.email"
                                fullWidth
                                isMandatory
                                formLabel={translate("recipientEmail")}
                                autoComplete={"off"}
                            />
                            <DatePickerInput
                                formLabel={translate("giftCardDeliveryDate")}
                                name="recipient.deliveryDate"
                                dateFormat="YYYY-MM-DD"
                                fullWidth
                                locale={"sv-se"}
                                minDate={moment()}
                                autoComplete={"off"}
                            />
                            <FormInput
                                name="recipient.message"
                                fullWidth
                                formLabel={translate("recipientMessage")}
                                component={Textarea}
                                style={{
                                    resize: "none"
                                }}
                            />
                        </Box>
                    );
                }}
            </ValidateStep>
        </>
    );
};
