import React from "react";
import { FiCheck } from "@react-icons/all-files/fi/FiCheck";

import { Box, Label } from "Atoms";

type PickupOptionLabelProps = {
    isCompleted: boolean;
    text: string;
};

export const CustomerPickupOptionLbl: React.FC<PickupOptionLabelProps> = ({ isCompleted, text }) => {
    return (
        <Label themeColor={isCompleted ? "green" : "gray"} justifyContent="center" mr={4} circular>
            {isCompleted ? <Box as={FiCheck} size="18px" /> : text}
        </Label>
    );
};
