import React from "react";

import { Button, ButtonProps } from "Atoms";

type OnlineCheckoutGenericPaymentButtonProps = ButtonProps;

export const OnlineCheckoutGenericPaymentButton: React.FC<OnlineCheckoutGenericPaymentButtonProps> = props => (
    <Button
        borderColor="gray.400"
        themeColor="blue"
        background="none"
        variant="outline"
        size="lg"
        rounded="lg"
        type="submit"
        _focus={{
            boxShadow: "outline"
        }}
        {...props}
    />
);
