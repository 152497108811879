import React from "react";

import { Box, Flex } from "Atoms";
import { LanguageHeader } from "Components";
import { CustomerPickupFormComponent, CustomerPickupOptionLbl, PickupOptions } from ".";

export const TakeAwayContent: React.FC<CustomerPickupFormComponent> = props => (
    <Box>
        <Flex align="center" mb={4}>
            <CustomerPickupOptionLbl isCompleted={false} text="2" />
            <LanguageHeader tid="dateAndTime" as="h3" size="md" color="gray.800" m="0" />
        </Flex>
        <PickupOptions {...props} />
    </Box>
);
