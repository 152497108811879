import React from "react";
import Media from "react-media";

import { Box, Text, NewList, NewListItem, FormHelperText, Flex } from "Atoms";
import { useOnline, EatingPreference, useLanguage, isEatingPreferenceCatering } from "Providers";
import { OrderWindow, Settings } from "Types";
import { roundNumber } from "NumberUtils";
import { getCartTotalPrice, formatSwedishFinancialNumbers, calculateDeliveryFee } from "Utils";
import { LanguageText } from "Components";
import { getMinOrderTotalAmount } from "OnlineUtils";
import { useQoplaStore } from "Stores";

type Props = {
    orderWindow: OrderWindow;
};

export const OnlineOrderDeliveryInfoMobile: React.FC<Props> = ({ orderWindow }) => {
    const { selectedShop: shop } = useQoplaStore();

    const {
        foodOptions: { eatingPreference, deliveryInformation }
    } = useOnline();
    const { translate } = useLanguage();

    const settings = shop?.settings as Settings;
    const minAmountFreeDelivery = settings.homeDeliverySettings.minAmountFreeDelivery || 0;

    const isHomeDelivery = eatingPreference === EatingPreference.HOME_DELIVERY;
    const isCateringDelivery = eatingPreference === EatingPreference.CATERING_DELIVERY;

    const isDelivery = isHomeDelivery || isCateringDelivery;
    const _isCatering = isEatingPreferenceCatering(eatingPreference);
    const isHomeDeliveryOrCatering = isHomeDelivery || _isCatering;

    if (!isHomeDelivery && !_isCatering) return null;

    const renderEmptyCart = !orderWindow?.cartProducts?.length;

    const minOrderTotalAmount = getMinOrderTotalAmount(eatingPreference, settings);
    const subTotal = roundNumber(getCartTotalPrice(orderWindow?.cartProducts));
    const addedDeliveryFee = calculateDeliveryFee(subTotal, minAmountFreeDelivery, deliveryInformation!.fee);

    const hasMetMinHomeDeliveryAmount = isHomeDeliveryOrCatering && subTotal < minOrderTotalAmount;

    const titleTextKey = _isCatering ? "catering" : "homeDelivery";
    const resTextKey = _isCatering ? "restForCatering" : "restForHomeDelivery";

    return (
        <Media queries={{ small: { maxWidth: 991 } }}>
            {matches => {
                if (matches.small) {
                    return (
                        <Box p={4} rounded="md" boxShadow="lg" mb={6} bg="white">
                            <LanguageText tid={titleTextKey} fontSize="lg" fontWeight="bold" />
                            <NewList listStyle="none" color="gray.700">
                                {hasMetMinHomeDeliveryAmount && (
                                    <NewListItem display="flex" justifyContent="space-between">
                                        <LanguageText tid={resTextKey} fontSize="lg" />
                                        <Text fontSize="lg">
                                            {hasMetMinHomeDeliveryAmount
                                                ? formatSwedishFinancialNumbers(minOrderTotalAmount - subTotal)
                                                : "0 kr"}{" "}
                                        </Text>
                                    </NewListItem>
                                )}
                                {isDelivery && (
                                    <NewListItem display="flex" flexDirection="column">
                                        <Flex justify="space-between">
                                            <LanguageText tid="deliveryFee" fontSize="lg" m="0" />
                                            <Text fontSize="lg" m="0">
                                                {renderEmptyCart
                                                    ? `${deliveryInformation!.fee} kr`
                                                    : addedDeliveryFee
                                                    ? `${addedDeliveryFee} kr`
                                                    : translate("free")}
                                            </Text>
                                        </Flex>
                                        {!!minAmountFreeDelivery && subTotal <= minAmountFreeDelivery && (
                                            <FormHelperText as="span">
                                                {translate("freeDeliveryOver")} {minAmountFreeDelivery} kr
                                            </FormHelperText>
                                        )}
                                    </NewListItem>
                                )}
                            </NewList>
                        </Box>
                    );
                }
                return null;
            }}
        </Media>
    );
};
