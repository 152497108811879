import React from "react";

import { FormRadios } from "Molecules";
import { useLanguage } from "Providers";
import { useQoplaStore } from "Stores";
import { CustomerType } from "Types";

export const CateringContentCustomerTypeField: React.FC<{}> = () => {
    const { translate } = useLanguage();
    const { selectedShop } = useQoplaStore();

    /*
     * Only companies can pay with invoices.
     * Therefor we do not need to display these radio buttons if
     * the shop does not accept invoices.
     */
    const acceptInvoicePayment = selectedShop?.settings?.cateringSettings?.acceptInvoicePayment ?? false;
    if (!acceptInvoicePayment) {
        return null;
    }

    const options = [
        { key: translate("private"), value: CustomerType.Private },
        { key: translate("business"), value: CustomerType.Organization }
    ];

    return <FormRadios name="customerType" options={options} formLabel={translate("orderingAs")} />;
};
