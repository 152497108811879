import React from "react";
import { RiErrorWarningLine } from "@react-icons/all-files/ri/RiErrorWarningLine";

import { Text, Box, Stack } from "Atoms";
import { TypeOfServiceDisruption } from "Types";
import { useLanguage } from "Providers";

type Props = {
    disruptionType: TypeOfServiceDisruption;
};

export const OnlineCheckoutPaymentDisruption: React.FC<Props> = ({ disruptionType }) => {
    const { translate } = useLanguage();
    const disruptionMessage =
        disruptionType === TypeOfServiceDisruption.SWISH ? "swishServiceIsDisrupted" : "cardServiceIsDisrupted";

    return (
        <Stack stretch={2} justify="center" isInline align="center" mb={2}>
            <Box as={RiErrorWarningLine} color="red.500" size={5} />
            <Text color="red.500" as="span">
                {translate(disruptionMessage)}
            </Text>
        </Stack>
    );
};
