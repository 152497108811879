import React from "react";
import { Formik, FormikHelpers } from "formik-next";
import moment from "moment";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { deviceDetect } from "react-device-detect";
import { useMedia } from "react-media";
import { useNavigate } from "react-router-dom";

import { TranslateFunc, useAuthUser, useLanguage, useOnline } from "Providers";
import { useCookieConsent, useMothershipMutation, useImperativeQuery, useSessionStorage } from "Hooks";
import { PaymentMethod, SessionConstants } from "Constants";
import { Flex, Image, Text, Box } from "Atoms";
import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Step,
    StepWizard,
    StandardStepButtons
} from "Organisms";
import { CookieKeys, GiftCard as GiftCardType, Shop, FoodOptions } from "Types";
import { LanguagePicker } from "Components";
import {
    OnlineOrderGiftCardInformation,
    WHICH_STEP,
    OnlineOrderGiftCardValue,
    OnlineOrderGiftCardPaymentMethod,
    OnlineOrderGiftCardRecipient
} from "./components";
import "../../onlineOrder.scss";
import { ADD_USER_GIFT_CARD } from "GraphQLMutations";
import { convertCheckoutFormValuesToContactInformation } from "../../../checkout/utils";
import { IncludedShops } from "../../../giftCardStatusContainer/components/IncludedShops";
import { getInitialValues } from "../../../../pages/checkout/components/OnlineCheckoutForm/utils";
import { OnlineCheckoutFormValues } from "../../../../pages/checkout/components";
import { parseDateForJavaLocalDateTime } from "Utils";
import { GET_USER_GIFT_CARD_BY_ORDER_ID } from "GraphQLQueries";
import { newMothershipApolloClient } from "../../../../../graphql/clients";

type Props = {
    openDrawer: boolean;
    onCloseDrawer: () => void;
    giftCardSettings: GiftCardType;
    shop: Shop;
};

export type Recipient = {
    giftCardId: string;
    customValue: number;
    name: string;
    email: string;
    message: string;
    deliveryDate: moment.Moment;
};

export type GiftCardFormValues = {
    recipient: Recipient;
    sender: OnlineCheckoutFormValues;
};

const getSchema = (minAmount: number, translate: TranslateFunc, maxAmount?: number): object => {
    const mustFillIn = Yup.string().required(translate("mustFillIn"));
    const isRequired = Yup.string().required(translate("required"));

    const phoneNumberRules = Yup.string()
        .matches(/^[+\d][0-9]*$/, translate("onlyNumbers"))
        .min(10, translate("phoneNumberError"));

    return Yup.object().shape({
        recipient: Yup.object().shape({
            customValue: Yup.string().test("validateCustomValue", "*", (value: number) => {
                if (maxAmount && maxAmount > 0) {
                    return value >= minAmount && value <= maxAmount;
                }
                return value >= minAmount;
            }),

            name: mustFillIn,
            email: Yup.string().email(translate("wrongEmailFormat")).required(translate("mustFillIn"))
        }),
        sender: Yup.object().shape({
            firstName: isRequired,
            email: Yup.string().email(translate("wrongEmailFormat")).required(translate("mustFillIn")),
            phoneNumber: Yup.string().when("sender.phoneNumber", {
                is: val => !val,
                then: phoneNumberRules
            })
        })
    });
};

export const OnlineOrderGiftCard: React.FC<Props> = ({ openDrawer, onCloseDrawer, giftCardSettings, shop }) => {
    const { translate, userLanguage } = useLanguage();
    const navigate = useNavigate();
    const { selectedCookie: formValsCookie } = useCookieConsent(CookieKeys.ONLINE_SAVE_FORM_VALS);
    const { userAccount } = useAuthUser();
    const { clientInfo } = useOnline();
    const [addUserGiftCard] = useMothershipMutation(ADD_USER_GIFT_CARD);
    const getUserGiftCardByOrderId = useImperativeQuery(GET_USER_GIFT_CARD_BY_ORDER_ID, {
        client: newMothershipApolloClient,
        fetchPolicy: "network-only"
    });
    const [giftCardState, setGiftCardState, removeGiftCardState] = useSessionStorage(SessionConstants.GIFT_CARD_VALUES);
    const isSmallScreen = useMedia({ query: "(max-width: 798px)" });
    const { imageUrl, minAmount, maxAmount, description } = giftCardSettings;

    const hasGiftCardState = !!giftCardState;

    const foodOptions: FoodOptions = {
        eatingPreference: "",
        pickupOptions: { time: "", date: "", isEarliest: false },
        deliveryInformation: null,
        cateringInformation: null
    };

    let initialValues: GiftCardFormValues;
    if (hasGiftCardState) {
        initialValues = {
            ...giftCardState,
            recipient: {
                ...giftCardState.recipient,
                deliveryDate: moment(giftCardState.recipient.deliveryDate)
            }
        };
    } else {
        const senderInitialValues = getInitialValues(formValsCookie, foodOptions, "", false, userAccount);

        initialValues = {
            recipient: {
                giftCardId: giftCardSettings.id!,
                customValue: 0,
                name: "",
                email: "",
                message: "",
                deliveryDate: moment()
            },
            sender: { ...senderInitialValues, lastName: "" }
        };
    }

    const handleCloseDrawer = () => {
        removeGiftCardState();
        onCloseDrawer();
    };

    const generateManualCode = () => {
        const time = new Date().getTime();
        return Math.floor(Math.random() * time)
            .toString(36)
            .toUpperCase();
    };

    const pay = async (values: GiftCardFormValues): Promise<boolean> => {
        const { recipient } = values;
        const sender = convertCheckoutFormValuesToContactInformation(values.sender);
        /** Set gift card state - if payment fails it will return to step 4 */
        setGiftCardState(values);
        const paymentMethod = values.sender.paymentInformation.paymentMethod;

        const manualGiftCardCode = generateManualCode();

        const { data } = await addUserGiftCard({
            variables: {
                giftCardOrderDTO: {
                    qr: false,
                    userLanguage,
                    deviceInformation: { ...deviceDetect(window.navigator.userAgent), locale: userLanguage },
                    shopId: shop.id,
                    // This userAccountId is the buyer.  The recipients accountId will be attached to the giftcard when they accept it.
                    userAccountId: userAccount?.id,
                    contactInformation: sender,
                    amount: recipient.customValue,
                    paymentMethod: paymentMethod,
                    deliveryDate: parseDateForJavaLocalDateTime(recipient.deliveryDate.startOf("day")),
                    recipientDetails: {
                        recipientName: recipient.name,
                        recipientEmail: recipient.email.toLowerCase(),
                        recipientMessage: recipient.message
                    },
                    clientInformation: {
                        ...clientInfo,
                        purchaseTime: new Date().getTime()
                    },
                    manualCode: manualGiftCardCode
                }
            }
        });

        if (data && data.addGiftCard) {
            const { webPaymentResponse, order } = data.addGiftCard;

            /**
             * Lookup the gift card by the order id, because we don't receive this in the paymentResponse
             */
            const getUserGiftCardByOrderIdResponse = await getUserGiftCardByOrderId({
                orderId: order.id
            });
            const userGiftCard = getUserGiftCardByOrderIdResponse.data.getUserGiftCardByOrderId;

            if (webPaymentResponse && !!userGiftCard?.id) {
                if (paymentMethod == PaymentMethod.SWISH) {
                    /**
                     * Use the userGiftCardId as the path parameter
                     */
                    navigate(`/giftcard-status/${userGiftCard.id}?paymentMethod=${paymentMethod}`, {
                        state: {
                            paymentType: paymentMethod,
                            qrCode: webPaymentResponse.qrCode,
                            token: webPaymentResponse.token
                        }
                    });
                } else {
                    if (!!webPaymentResponse.redirectUrl) {
                        window.location.href = webPaymentResponse.redirectUrl;
                    }
                }
                return true;
            }
        }
        return false;
    };

    const handleGiftCardValues = async (values: GiftCardFormValues, actions: FormikHelpers<GiftCardFormValues>) => {
        // Execute payment (includes redirect)
        const isSuccessful = await pay(values);
        if (!isSuccessful) {
            toast.error(`${translate("somethingWentWrong")}`);
        }
    };

    const buttonStyles = {
        leftButtonStyle: {
            color: "white",
            backgroundColor: "gray.600",
            fontSize: "0.9rem",
            _hover: {
                backgroundColor: "gray.600"
            }
        },
        rightButtonStyle: {
            color: "white",
            backgroundColor: "green.600",
            fontSize: "0.9rem",
            _hover: {
                backgroundColor: "green.400"
            }
        }
    };

    const centerElements = {
        display: "flex",
        justifyContent: "center"
    };

    return (
        <Drawer open={openDrawer} onClose={handleCloseDrawer} size="md" duration={7}>
            <DrawerOverlay />
            <DrawerContent bg="white" overflow="auto" id="hidden-scroll">
                <DrawerHeader>
                    <LanguagePicker />
                    <DrawerCloseButton onClick={handleCloseDrawer} />
                </DrawerHeader>
                <DrawerBody maxHeight={"100vh"}>
                    <Flex flexDirection="column">
                        <Box
                            position="relative"
                            rounded="lg"
                            h={isSmallScreen ? "120px" : "150px"}
                            backgroundColor="black"
                            py={imageUrl ? 0 : 2}
                        >
                            <Image
                                src={imageUrl}
                                h={isSmallScreen ? "120px" : "150px"}
                                objectFit="cover"
                                rounded="lg"
                            />

                            <Flex
                                flexDirection="column"
                                justifyContent="space-between"
                                alignItems="center"
                                height="100%"
                                color="white"
                                position="absolute"
                                top="0"
                                left="0"
                                width="100%"
                            >
                                <Box bg="newPrimary" width="100%" opacity="0.9" roundedTop="lg">
                                    <Text
                                        mb={0}
                                        fontFamily="giftcard"
                                        textAlign="center"
                                        fontSize={{ xs: "2.5rem", lg: "3rem" }}
                                    >
                                        {translate("giftCard")}
                                    </Text>
                                </Box>
                                <Flex alignItems="center" h="auto" pb="0.5rem">
                                    <Box height={{ xs: "4rem", lg: "5rem" }}>
                                        <Image
                                            src={shop.imageUrl}
                                            rounded="lg"
                                            h="120px"
                                            w="auto"
                                            maxH="100%"
                                            objectFit="contain"
                                        />
                                    </Box>
                                    {giftCardSettings?.name !== "Digitalt Presentkort" && (
                                        <Text ml={4}>{giftCardSettings?.name}</Text>
                                    )}
                                </Flex>
                            </Flex>
                        </Box>
                        <StepWizard
                            noSteps={4}
                            skipToStep={giftCardState && 4}
                            height="100%"
                            overflow={"auto"}
                            display="grid"
                            gridTemplateRows="65px min-content min-content 1fr"
                            titleAboveButtons={true}
                            alignItems="flex-end"
                            titleProps={{ paddingTop: "1rem", paddingBottom: "-2rem" }}
                            stepsToValidate={[2, 3]}
                        >
                            <StandardStepButtons
                                {...buttonStyles}
                                forwardText={translate("continue")}
                                backText={translate("back")}
                            />
                            <Formik<GiftCardFormValues>
                                initialValues={initialValues}
                                validationSchema={getSchema(minAmount ?? 0, translate, maxAmount ?? 0)}
                                onSubmit={handleGiftCardValues}
                            >
                                {({ values, handleSubmit, setFieldValue, isSubmitting, isValid }) => {
                                    return (
                                        <form
                                            onSubmit={handleSubmit}
                                            style={{ display: "grid", alignSelf: "flex-start" }}
                                        >
                                            <Step
                                                step={1}
                                                {...centerElements}
                                                flexDirection="column"
                                                alignItems="center"
                                                marginTop="1.5rem"
                                                title={`${translate("importantInformation")}`}
                                            >
                                                <>
                                                    <OnlineOrderGiftCardInformation
                                                        description={description}
                                                        minAmount={minAmount}
                                                        whichStep={WHICH_STEP.GENERAL_SELECTION}
                                                        translate={translate}
                                                    />

                                                    <IncludedShops
                                                        paddingTop="3rem"
                                                        pl={isSmallScreen ? "1rem" : "3rem"}
                                                        color="gray.900"
                                                        giftCardSettings={giftCardSettings}
                                                        width="100%"
                                                        isClickable={false}
                                                    />
                                                </>
                                            </Step>
                                            <Step
                                                step={2}
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                marginTop="2rem"
                                                title={`${translate("choosePayment")}`}
                                            >
                                                <Flex direction="column" style={{ gap: "2rem" }}>
                                                    <OnlineOrderGiftCardValue
                                                        minAmount={minAmount}
                                                        translate={translate}
                                                        setFieldValue={setFieldValue}
                                                    />
                                                    <OnlineOrderGiftCardInformation
                                                        description={description}
                                                        minAmount={minAmount}
                                                        whichStep={WHICH_STEP.MONEY_SELECTION}
                                                        translate={translate}
                                                    />
                                                </Flex>
                                            </Step>
                                            <Step step={3} marginTop="1.5rem" title={`${translate("recipient")}`}>
                                                <OnlineOrderGiftCardRecipient translate={translate} />
                                            </Step>
                                            <Step
                                                step={4}
                                                marginTop="1.5rem"
                                                title={translate("yourContactInformation")}
                                            >
                                                <OnlineOrderGiftCardPaymentMethod
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    isValid={isValid}
                                                    isDirty={hasGiftCardState}
                                                    isSubmitting={isSubmitting}
                                                />
                                            </Step>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </StepWizard>
                    </Flex>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
