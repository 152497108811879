import React from "react";

import { Box, NewList, NewListItem, Text } from "Atoms";
import { Settings } from "Types";
import { englishDayToSwedishTable, englishDayToEnglishTable, formatDateToLocal } from "Utils";
import { useLanguage, Languages } from "Providers";
import { useQoplaStore } from "Stores";

const getHourTextKey = (n: number) => (n === 1 ? "hour" : "hours");

export const CateringContentInfoText: React.FC<{}> = () => {
    const { translate, userLanguage } = useLanguage();
    const { selectedShop: shop } = useQoplaStore();
    const settings = shop?.settings as Settings;

    const tableToUse = userLanguage === Languages.EN ? englishDayToEnglishTable : englishDayToSwedishTable;

    return (
        <Box my={4} p={4} rounded="md" backgroundColor="gray.200">
            <NewList listStyle="none" stretch={2}>
                {!!settings.cateringSettings.lastOrderDay?.dayOfWeek ? (
                    <NewListItem fontSize="lg">
                        {translate("restaurantAcceptsOrdersToEvery")}{" "}
                        <Text as="strong">
                            {tableToUse[settings.cateringSettings.lastOrderDay.dayOfWeek]}{" "}
                            {settings.cateringSettings.lastOrderDay.stoppingHour}.
                        </Text>
                    </NewListItem>
                ) : !!settings.cateringSettings.lastOrderDay?.date ? (
                    <NewListItem fontSize="lg">
                        {translate("restaurantAcceptsOrdersTo")}{" "}
                        <Text as="strong">
                            {tableToUse[settings.cateringSettings.lastOrderDay.dayOfWeek]}{" "}
                            {formatDateToLocal(settings.cateringSettings.lastOrderDay.date)}.
                        </Text>
                    </NewListItem>
                ) : (
                    <NewListItem fontSize="lg">
                        {translate("orderAtLeast")}{" "}
                        <Text as="strong">
                            {settings.cateringSettings.noticeHours}{" "}
                            {translate(getHourTextKey(settings.cateringSettings.noticeHours))}
                        </Text>{" "}
                        {translate("inAdvanced")}.
                    </NewListItem>
                )}
                <NewListItem fontSize="lg">{translate("processCateringRequest")} </NewListItem>
                <NewListItem fontSize="lg">{translate("cateringConfirmation")}</NewListItem>
            </NewList>
        </Box>
    );
};
