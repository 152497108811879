import React from "react";
import { MdDateRange } from "@react-icons/all-files/md/MdDateRange";
import { MdEmail } from "@react-icons/all-files/md/MdEmail";
import { MdMessage } from "@react-icons/all-files/md/MdMessage";
import { MdPayment } from "@react-icons/all-files/md/MdPayment";
import { MdDescription } from "@react-icons/all-files/md/MdDescription";
import { GiReceiveMoney } from "@react-icons/all-files/gi/GiReceiveMoney";
import { IoMdInformationCircleOutline } from "@react-icons/all-files/io/IoMdInformationCircleOutline";

import { TranslateFunc } from "Providers";
import { Box, Flex, Grid } from "Atoms";
import { IconTextWrapper } from "Molecules";

const boxStyles = {
    padding: "1rem",
    borderRadius: "1rem",
    backgroundColor: "gray.100",
    height: "auto"
};

const iconStyles = {
    templateColumns: "min-content 1fr",
    alignItems: "center",
    paddingBottom: "1rem",
    gap: "1rem"
};

export enum WHICH_STEP {
    GENERAL_SELECTION = "GENERAL_SELECTION",
    MONEY_SELECTION = "MONEY_SELECTION"
}

type Props = {
    description?: string;
    minAmount: number;
    whichStep: WHICH_STEP;
    translate: TranslateFunc;
};

export const OnlineOrderGiftCardInformation: React.FC<Props> = ({ description, minAmount, whichStep, translate }) => {
    const paymentText = `${translate("giftCardPayment")} (min ${minAmount} kr)`;
    const cardDescription = description ? description : translate("giftCardDescription");

    if (whichStep === WHICH_STEP.GENERAL_SELECTION) {
        return (
            <Flex flexDirection="column" justifyContent="flex-start" {...boxStyles} style={{ gap: "0.5rem" }}>
                <IconTextWrapper text={cardDescription!} icon={MdDescription} {...iconStyles} />
                <IconTextWrapper text={translate("giftCardValid")} icon={MdDateRange} {...iconStyles} />
                <IconTextWrapper text={translate("giftCardEmail")} icon={MdEmail} {...iconStyles} />
                <IconTextWrapper
                    text={translate("giftCardUseEmail")}
                    icon={IoMdInformationCircleOutline}
                    {...iconStyles}
                />
                <IconTextWrapper text={translate("giftCardMessage")} icon={MdMessage} {...iconStyles} />
            </Flex>
        );
    }

    const newDate = new Date();
    newDate.setFullYear(newDate.getFullYear() + 1);
    const validDate = newDate.toLocaleDateString("sv-SE");

    return (
        <Flex flexDirection="column" justifyContent="flex-start" {...boxStyles} style={{ gap: "0.5rem" }}>
            <IconTextWrapper text={translate("giftCardMoney")} icon={GiReceiveMoney} {...iconStyles} />
            <IconTextWrapper text={paymentText} icon={MdPayment} {...iconStyles} />
            <Grid {...iconStyles} height="auto">
                <Box as={MdDateRange} fontWeight="800" fontSize="xl" color="newPrimary"></Box>
                <Box as="span" fontSize="md" textAlign="left" color="gray.800">
                    {`${translate("validTo")}: `}
                    <Box as="span" fontSize="2xl" textAlign="left" color="red.500">
                        {validDate}
                    </Box>
                </Box>
            </Grid>
        </Flex>
    );
};
