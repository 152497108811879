import moment from "moment";

import { ILanguages } from "Providers";
import { ActiveHour, CateringSettings, Menu, FoodOptions, EatingPreference } from "Types";
import { CustomerPickupFormik, PickupFormEatingPreference } from "../OnlineOrderPickupModal";
import { getCateringPickupOptions } from ".";
import { getMergedActiveHoursByEatingOption } from "Utils";

const getEatingSelection = (
    eatingPreference: EatingPreference | "",
    eatingOptions: EatingPreference[]
): PickupFormEatingPreference => {
    const isCatering =
        eatingPreference === EatingPreference.CATERING_DELIVERY ||
        eatingPreference === EatingPreference.CATERING_TAKE_AWAY;
    if (eatingOptions && eatingOptions.length === 1) {
        if (isCatering) return EatingPreference.CATERING;
        return eatingOptions[0] as PickupFormEatingPreference;
    } else if (isCatering) {
        return EatingPreference.CATERING;
    } else {
        return eatingPreference as PickupFormEatingPreference;
    }
};

const getPickupOptions = (
    pickup: { time: string | undefined; date: string | undefined; isEarliest: boolean },
    eatingOptions: EatingPreference[]
) => {
    const pickupTime = () => {
        if (pickup.time) {
            return pickup.time;
        } else if (eatingOptions.length === 1) {
            return "asap";
        }
        return "";
    };

    return {
        pickupDate: pickup.date || moment().format("YYYY-MM-DD"),
        pickupTime: pickupTime()
    };
};

export const getInitialValues = (
    foodOptions: FoodOptions,
    eatingOptions: EatingPreference[],
    cateringSettings: CateringSettings,
    activeHours: ActiveHour[],
    userLanguage: ILanguages,
    backendDiff: number,
    menus: Menu[],
    isQuickServeActive: boolean,
    shopCity?: string | null
): CustomerPickupFormik => {
    const { eatingPreference, pickupOptions } = foodOptions;

    const eatingSelection = getEatingSelection(eatingPreference, eatingOptions);

    let pickupValues;
    if (eatingSelection === EatingPreference.CATERING) {
        pickupValues = getCateringPickupOptions(
            cateringSettings,
            activeHours,
            userLanguage,
            backendDiff,
            pickupOptions
        );
    } else {
        pickupValues = getPickupOptions(pickupOptions, eatingOptions);
    }

    let onlineActiveHours = activeHours;
    if (eatingSelection === EatingPreference.HOME_DELIVERY || eatingSelection === EatingPreference.CATERING) {
        onlineActiveHours = getMergedActiveHoursByEatingOption(menus, eatingSelection as any);
    }

    const hasNoDeliveryOption = !cateringSettings.deliveryAreas?.length;

    return {
        eatingPreference: eatingSelection,
        postCode: "",
        postAddress: "",
        willDeliverCatering: hasNoDeliveryOption ? "NO" : "",
        customerType: "",
        city: shopCity || "",
        tmpDeliveryInformation: null,
        tmpDeliveryType: "",
        isEarliest: pickupOptions.isEarliest || false,
        onlineActiveHours,
        timeInterval: "",
        pickupStartDate: moment(),
        tmpPrevPostCode: "",
        isQuickServeActive: isQuickServeActive ?? false,
        isAfterMidnight: false,
        ...pickupValues
    };
};
