import React from "react";

import { Box, Flex } from "Atoms";
import { LanguageHeader } from "Components";
import { Settings } from "Types";
import { useQoplaStore } from "Stores";
import { CateringContentWithCustomDatesContent, CateringContentWithDefaultContent } from "./components";
import { CustomerPickupFormComponent, CustomerPickupOptionLbl } from "..";
import { hasCustomDatesInDeliveryAreas } from "../../utils";
import { RÅ_OCH_SÅ_AB_COMPANY_ID } from "Constants";

export const CateringContent: React.FC<CustomerPickupFormComponent> = props => {
    const { hasOnlyCatering } = props;

    const { selectedShop: shop } = useQoplaStore();
    const settings = shop?.settings as Settings;

    const isRaOchSa = shop?.companyId === RÅ_OCH_SÅ_AB_COMPANY_ID;

    const _hasCustomDatesInDeliveryAreas = hasCustomDatesInDeliveryAreas(settings.cateringSettings);

    return (
        <Box>
            <Flex align="center" mb={4}>
                {!hasOnlyCatering && <CustomerPickupOptionLbl isCompleted={false} text="2" />}
                <LanguageHeader
                    tid={hasOnlyCatering ? "information" : "cateringOptions"}
                    as="h3"
                    size="md"
                    color="gray.800"
                    m="0"
                />
            </Flex>
            <Box px={4}>
                {_hasCustomDatesInDeliveryAreas || isRaOchSa ? (
                    <CateringContentWithCustomDatesContent {...props} />
                ) : (
                    <CateringContentWithDefaultContent {...props} />
                )}
            </Box>
        </Box>
    );
};
